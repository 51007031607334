import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

export const Price = (  ) => {
    const { slug } = useParams();
    const [ price, setPrice ] = useState([])
    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;
        const fetchTags = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json)
                setPrice(json?.data[0]?.attributes)
            } catch (error) {
                console.log("error", error);
            }
        }
        fetchTags();
    }, [])



   

    return (
        <div className="price mt20 mb10"><h3> Desde <b style={{fontSize:18}}>UF {price?.precio}</b> Hasta <b style={{fontSize:18}}>UF {price?.Precio2}</b>  </h3></div>
    )

    
}
