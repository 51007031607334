import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getDepaById } from '../api/buydepa';
import { getCotizacionByEmail } from '../api/cotizaciones';

const CotizacionDetails = () => {
    const [ user, setUser ] = React.useState([])
    const [ cotizacion, setCotizacion ] = React.useState([])
    const { email } = useParams();

    const getCotizacion = async () => {
        const response = await getCotizacionByEmail(email)
        setUser(response.data)
    }



    useEffect(() => {
        getCotizacion()
    }, [])


    const getDepaID = async (id) => {
        const response = await getDepaById(id)

        const address = response?.data?.attributes?.address
        const image = response?.data?.attributes?.images[0]?.main_image_url?.data?.attributes?.url
        return {
            address,
            image
        }
    }


    //create a function to fuse the user data and depa data

    const fuseData = async () => {
        const data = await Promise.all(user.map(async (item) => {
            const {address, image} = await getDepaID(item.attributes.buydepaid)

            return {...item, address, image}
        }))
        setCotizacion(data)
    }

    useEffect(() => {
        fuseData()
    }, [user])

    






  return (
    <section>
                <h1 className='
                text-2xl
                font-bold
                text-gray-800
                px-8
                '> Mis Cotizaciones ({user.length})</h1>

    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        
        
        {
            cotizacion && cotizacion?.map((user) => ( 
            <Link to={`/cotizacion/${user.id}/${user.attributes?.uuid}`} key={user.id}>
            <div  className="cursor-pointer rounded-xl bg-white p-3 shadow-lg hover:shadow-xl">
            <div className="relative flex items-end overflow-hidden rounded-xl">
                <img src={user.image || 'https://buydepa.com/default_image.svg'} alt="wallpaper" />

                <div className="absolute bottom-3 left-3 inline-flex items-center rounded-lg bg-white p-2 shadow-md">
                    N°

                    <span className="ml-1 text-sm text-slate-400">{user.id}</span>
                </div>
            </div>

            <div className="mt-1 p-2">
                <h2 className="text-slate-700 text-2xl">{user?.address}</h2>


            </div>
        </div>
        </Link>
         ))


        }
        
        

       

    </div>
</section>

  )
}

export default CotizacionDetails