import React, { useEffect, useState } from 'react';
import { Page, Document, StyleSheet, Image, View, Text, PDFViewer, Font } from '@react-pdf/renderer';

import Roboto from './fonts/Roboto-Regular.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';
import { getDepaById } from '../../api/buydepa';
import useUF from '../../hooks/useUF';
import { milles } from '../../utils/toMilles';
Font.register({
    family: 'Roboto',
    format: "truetype",
    src: Roboto
  });

    Font.register({
    family: 'RobotoBold',
    format: "truetype",
    src: RobotoBold
    });




const styles = StyleSheet.create({
    page: {
        width: '100%',
        height: '100%'
    
      },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },

  pageBackground: {
    position: 'absolute',
    height: '100vh',
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },

  text:{
    fontFamily: 'Roboto',
    fontSize: 12,
  },

  cotizacion :{
    fontFamily: 'RobotoBold',
    fontSize: 15,
    left: 50,
    right: 0,
    top: '180px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },

  number:{
    fontFamily: 'RobotoBold',
    fontSize: 13,
    left: 500,
    right: 0,
    top: '180px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },

  proyecto :{
    fontFamily: 'Roboto',
    fontSize: 15,
    left: 50,
    right: 0,
    top: '230px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },

  date :{
    fontFamily: 'Roboto',
    fontSize: 15,
    left: 50,
    right: 0,
    top: '250px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",

  },
  senor :{
    fontFamily: 'Roboto',
    fontSize: 15,
    left: 330,
    right: 0,
    top: '230px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },

  rut :{
    fontFamily: 'Roboto',
    fontSize: 15,
    left: 330,
    right: 0,
    top: '250px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",

  },
  withBold:{
    fontFamily: 'RobotoBold',
  },

  withLeftAndPadding:{
    textAlign: 'right',
    paddingRight: '50px',
  },
  cars :{
    fontFamily: 'RobotoBold',
    color: 'gray',
    fontSize: 15,
    left: 50,
    right: 0,
    top: '300px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },
  cars2 :{
    fontFamily: 'RobotoBold',
    color: 'gray',
    fontSize: 15,
    left: 50,
    right: 0,
    top: '500px',
    letterSpacing: '0.5px',
    transformOrigin: "bottom left",
    position :"absolute",
    whiteSpace : "pre",
    lineHeight : "1.5",
  },
  table:{

    left: 0,
    right: 0,
    top: '350px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },
  table2:{

    left: 150,
    right: 0,
    top: '350px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },

  table3:{

    left: 250,
    right: 0,
    top: '350px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },
  table4:{

    left: 400,
    right: 0,
    top: '350px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },

  table5:{

    left: 0,
    right: 0,
    top: '550px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },
  table6:{

    left: 150,
    right: 0,
    top: '550px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },

  table7:{

    left: 250,
    right: 0,
    top: '550px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },
  table8:{

    left: 400,
    right: 0,
    top: '550px',
    position :"absolute",
    paddingLeft: '50px',
    paddingRight: '50px',

  },

  tableTitle:{
    width: '150px',
    fontFamily: 'RobotoBold',
    fontSize: 12,
    textAlign: 'left',
    border: '1px solid black',
    padding: '5px',

  },

  tableTitle2:{
    width: '100px',
    fontFamily: 'Roboto',
    fontSize: 12,
    textAlign: 'left',
    border: '1px solid black',
    padding: '5px',

  },

  robotoBold: {
    fontFamily: 'RobotoBold',
  },

  roboto: {
    fontFamily: 'Roboto',
  }




});


const GeneratePDF = ({cotizacion}) => {
    const { id } = cotizacion;
    const { uf } = useUF()


const [loading, setLoading] = useState(true);
const [depa, setDepa] = useState({});

    const getDepa = async () => {
        const response = await getDepaById(cotizacion?.attributes?.buydepaid)
        setDepa(response.data)
        setLoading(false)
    }

    useEffect(() => {
        getDepa()
    }, [loading])

    
    if(loading  ) return <div>Loading...</div>


    return (
    <PDFViewer style={styles.viewer}>
    <Document>
    <Page size="A4" style={styles.page}>
    <View fixed={false}>
    <Image src={'https://res.cloudinary.com/dvnru03zi/image/upload/v1666838865/Cotizacio%CC%81n_Pa%CC%81g_01_1_-1_mydzx1.png'} style={styles.pageBackground} />
    <Text style={styles.cotizacion}>COTIZACIÓN</Text>
    <Text style={styles.number}>N° #{id}</Text>
    <Text style={styles.proyecto} >PROYECTO:{' '}{depa?.attributes?.address}</Text>
    <Text style={styles.date}>FECHA: {cotizacion?.attributes?.fecha}</Text>
    <Text style={styles.senor}>SEÑOR(A): {' '} <Text style={styles.withBold}>{cotizacion?.attributes?.nombre}</Text></Text>
    <Text style={styles.rut} >RUT: {' '} <Text>{cotizacion?.attributes?.rut}</Text></Text>
    <Text style={styles.cars}>I. CARACTERISTICAS</Text>
    <View style={styles.table}>
        <Text style={styles.tableTitle}>DEPARTAMENTO </Text>
        <Text style={styles.tableTitle}>BAÑOS</Text>
        <Text style={styles.tableTitle}>DORMITORIOS</Text>
        <Text style={styles.tableTitle}>ORIENTACIÓN</Text>
    </View>
    <View style={styles.table2}>
        <Text style={styles.tableTitle2}>{depa?.attributes?.internal_address?.unit}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.typology?.bathrooms}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.typology?.bedrooms}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.amenities?.orientation || 'NO DEFINIDA'}</Text>
    </View>

    <View style={styles.table3}>
        <Text style={styles.tableTitle}>PISO </Text>
        <Text style={styles.tableTitle}>SUP. INTERIOR</Text>
        <Text style={styles.tableTitle}>SUP. TERRAZA</Text>
        <Text style={styles.tableTitle}>SUP. TOTAL</Text>
    </View>
    <View style={styles.table4}>
        <Text style={styles.tableTitle2}>{depa?.attributes?.internal_address?.floor}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.area?.area_int}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.area?.area_ext}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.area?.area_total}</Text>
    </View>
    <Text style={styles.cars2}>II. PRECIOS</Text>
    <View style={styles.table5}>
        <Text style={styles.tableTitle}>BIEN </Text>
        <Text style={styles.tableTitle}>DEPARTAMENTO </Text>
        <Text style={styles.tableTitle}>DEPARTAMENTO - DES</Text>
        <Text style={styles.tableTitle}>ESTACIONAMIENTO</Text>
        <Text style={styles.tableTitle}>BODEGA</Text>
    </View>
    <View style={styles.table6}>
        <Text style={[styles.tableTitle2, styles.robotoBold]}>N° BIEN</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.internal_address?.unit}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.internal_address?.unit}</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.garage_number ? depa?.attributes?.garage_number : '0' }</Text>
        <Text style={styles.tableTitle2}>{depa?.attributes?.storage_number ? depa?.attributes?.storage_number : '0'}</Text>
    </View>

    <View style={styles.table7}>
    <Text style={styles.tableTitle}>VALOR UF </Text>
        <Text style={[styles.tableTitle, styles.roboto]}>{Math.ceil(depa?.attributes?.selling_information?.final_selling_price)} </Text>
        <Text style={[styles.tableTitle, styles.roboto]}>{Math.ceil(depa?.attributes?.selling_information?.final_selling_price)} </Text>
        <Text style={[styles.tableTitle, styles.roboto]}>{depa?.attributes?.selling_information?.garage_selling_price}</Text>
        <Text style={[styles.tableTitle, styles.roboto]}>{depa?.attributes?.selling_information?.storage_selling_price}</Text>
    </View>
    <View style={styles.table8}>
        <Text style={[styles.tableTitle2, styles.robotoBold]}>VALOR $</Text>
        <Text style={styles.tableTitle2}>$ {milles(Math.ceil(depa?.attributes?.selling_information?.final_selling_price) * uf) }</Text>
        <Text style={styles.tableTitle2}>$ {milles(Math.ceil(depa?.attributes?.selling_information?.final_selling_price) * uf) }</Text>
        <Text style={styles.tableTitle2}>$ {milles(Math.ceil(depa?.attributes?.selling_information?.garage_selling_price) * uf) }</Text>
        <Text style={styles.tableTitle2}>$ {milles(Math.ceil(depa?.attributes?.selling_information?.storage_selling_price) * uf) }</Text>
    </View>
    </View>
    </Page>
  </Document>
    </PDFViewer>
  )
}

export default GeneratePDF