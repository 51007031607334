import React, { useEffect, useMemo } from 'react';
import cls from 'classnames';
import { createPortal } from 'react-dom';

import styles from './Modal.module.css';
import useModal from '../../hooks/useModal';

function Modal({ children, className, maxW, showBtnClose, outsideClickClose }) {

  const { setModal } = useModal();


  const stylesContentBox = useMemo(() => {
    const styles = {};

    if (maxW) styles.maxWidth = maxW;

    return styles;
  }, [maxW]);

  useEffect(() => {
    document.body.setAttribute('style', 'overflow: hidden');

    return () => {
      document.body.setAttribute('style', 'overflow-y: auto');
    };
  }, []);

  const handleClose = () => {
    //delete modal
    setModal(outsideClickClose, false);
    

  };

  //close modal when click outside
  const handleOutsideClick = (e) => {
    if (outsideClickClose) {
      if (e.target.id === 'modal') {
        handleClose();
      }
    }
  }


    // const handleClose = () => {
    //   //delete modal

    

  return createPortal(
    <div className={cls(styles.overlay, className)}
    id="modal"
    onClick={handleOutsideClick}

    >
      <div className={styles.contentBox} style={stylesContentBox}>
        {showBtnClose && (
          <button className={styles.btnClose} onClick={handleClose}></button>
        )}
        {children}
      </div>
    </div>,
    document.getElementById('root')
  );
}

export default Modal;
