import React, { useEffect, useState } from 'react'
import { Link } from '@chakra-ui/react'

export const Cities = () => {

    const [cities, setCities] = useState([])
    const [listening, setListening] = useState([])
    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getCities = async () => {
            const res = await fetch("https://app.goodprop.cl/api/ciudads?populate=*");
            const json = await res.json();
            setCities(json?.data);
        }

        getCities();
    }, [])

    useEffect(() => {
        const getListeningCities = async () => {
            const res = await fetch("https://app.goodprop.cl/api/properties?populate=ciudad");
            const json = await res.json();
            setListening(json?.data);
        }

        getListeningCities();
    }, [])

    // console.log(listening.filter((type) => type.attributes.ciudad.data.attributes.nombre), 'listening');

    // let listeningCount = listening.reduce((a, c) => { a[c.attributes.ciudad.data.attributes.nombre] = (a[c.attributes.ciudad.data.attributes.nombre] || 0) + 1; return a }, {})

    // console.log(listeningCount);

    const citiesList = listening.map((type) => type?.attributes?.ciudad?.data?.attributes?.nombre)



    //contar elementos repetidos
    const count = (arr) => {
        let counts = {};
        for (let i = 0; i < arr.length; i++) {
            let num = arr[i];
            counts[num] = counts[num] ? counts[num] + 1 : 1;
        }
        return counts;

    }


    console.log(count(citiesList));


    return (
        <section className="hot_localtion pb0">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>¡Mira dónde estamos!</h2>
                            <p>Elige la comuna en la que quieres invertir</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        cities.map((city) => {
                            return (
                                <div key={city?.attributes?.id} className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
                                    <Link href={'/proyectos'}  >

                                        <div className="property_city_home6 tac-767">
                                            <div className="thumb"><img className="w100" src={imgBaseURL + city?.attributes?.imagen?.data?.attributes?.url} alt={city?.attributes?.nombre} /></div>
                                            <div className="details">
                                                <h4>
                                                    <a href="#">{city?.attributes?.nombre}</a>
                                                </h4>
                                                <p>{
                                                    city?.attributes?.nombre == listening.filter((type) => type.attributes.ciudad.data.attributes.nombre) ?
                                                        <span className="badge badge-success">{count(citiesList)[city?.attributes?.nombre]}</span>
                                                        :
                                                        <span className="badge badge-danger">{count(citiesList)[city?.attributes?.nombre]}</span>

                                                }

                                                    {/* if cities is plural or singular */}
                                                    {
                                                        count(citiesList)[city?.attributes?.nombre] > 1 ?
                                                            <span> Propiedades</span>
                                                            :
                                                            <span> Propiedad</span>
                                                    }


                                                </p>
                                            </div>
                                        </div>
                                    </Link>

                                </div>
                            )
                        })
                    }



                </div>
            </div>

        </section>
    )
}
