import React, { useEffect, useState } from 'react';

export const Testimonials = () => {


    const [testimonials, setTestimonials] = useState([])
    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getTestimonials = async () => {
            const res = await fetch("https://app.goodprop.cl/api/testimonials?populate=*");
            const json = await res.json();
            setTestimonials(json?.data);
        }

        getTestimonials();
    }, [])

    let testimonialMap = testimonials && testimonials.map(testimonial => testimonial.attributes)


    return (
        <section className="our-testimonials bg-ptrn1 pb70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>Nuestros Testimonios</h2>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {
                        testimonialMap.map(testimonial =>
                            <div key="1" className="col-md-6 col-lg-6 col-xl-3">
                                <div className="testimonial_post text-center">
                                    <div className="details">
                                        <p>{testimonial.testimonio}</p>
                                    </div>
                                    <div className="thumb"> <img src={imgBaseURL + testimonial?.foto?.data?.attributes?.url} alt="1.png" /> </div>
                                    <div className="client_details">
                                        <h4 className="title">{testimonial.name}</h4>
                                        <div className="client-postn">{testimonial.empresa}</div>
                                    </div>
                                </div>
                            </div>

                        )
                    }



                </div>
            </div>
        </section>
    )
}
