import React from 'react'
import { Box, Button, Heading, Image, Text, UnorderedList } from '@chakra-ui/react'
import { IoLocationSharp } from 'react-icons/io5'
import { useEffect } from 'react'
import useModal from '../../hooks/useModal'
import CotizarModal from '../CotizarModal'
import { Link } from 'react-router-dom'


const PropertyCard = (properties) => {

    const { modals, setModal } = useModal();


    const [UF, setUF] = React.useState('')
    const { address, commune, images, selling_information, area, typology, slug  } = properties.attributes
    const finalPrice = Math.ceil(selling_information.final_selling_price)
  //  const price = Math.ceil(selling_information.selling_price)
    const rentPrice = selling_information.rent_price
    const areaTotal = area.area_total
    const dormitorios = typology.bedrooms
    const pie = selling_information.downpayment_financing
    const pieDecimal = pie / 100
    const cuotasQuantity = selling_information.storage_selling_price || 60

    const valorUF = async () => {

        const response = await fetch('https://mindicador.cl/api')
        const data = await response.json()
        const valorUF = data.uf.valor
        setUF(valorUF)
    }



    useEffect(() => {
        valorUF()
    }, [])
    

    //100 × ((362000 *12 ) / (2610 * 34054 ) )

    const capRate = () => {
        return (100 * ((rentPrice * 12) / (finalPrice * UF))).toFixed(2)
    }

    const milles = (number) => {
       let numero = number.toFixed()
        return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }



    const cuotas = () => {

        return (finalPrice * UF * pieDecimal) / cuotasQuantity
    }

    
  return (
    <>
            <Box className="col-sm-6 col-lg-12">
                <Box
                display= {{
                    base: "block!important",
                    md: "block!important",
                    lg: "flex!important",
                    xl: "flex!important"

                }}
                backgroundColor="#e8e8e8"
                borderRadius={'30px'}                
                className="feat_property list listing">

                    <Box
                    width={{
                        base: "100%!important",
                        md: "100%!important",
                        lg: "280px!important",
                        xl: "280px!important",


                    }}
                    className="thumb" borderRadius={'30px!important'}>
                                                                    <Link to={`/seminuevos/${slug}`}>

                        <Image 
                            h={{
                                base: "auto!important",
                                md: "100%!important",
                                lg: "100%!important",
                                xl: "100%!important",
                            }}
                        className="img-whp" src={images[0]?.main_image_url?.data?.attributes?.url || "https://buydepa.com/default_image.svg"} alt="fl1.jpg" />
                        </Link>
                    </Box>

                    <Box className="details" width= {{
                                        base: "100%!important",
                                        md: "100%!important",
                                        lg: "75%!important",
                                        xl: "75%!important",

                                    }}>
                                        
                        <Box className="tc_content">
                        <Link to={`/seminuevos/${slug}`}>

                            <Box className="wrapper float-left fn-md">
                                <Heading as="h4"
                                fontSize={{
                                    base: "1.5rem!important",
                                    md: "1.5rem!important",
                                    lg: "1.5rem!important",
                                    xl: "1.5rem!important",
                                }}
                                >{address}</Heading>
                                <p><IoLocationSharp style={{ display: 'inline'}} color={"ef6667"} size={20} /> {commune}</p>
                                <Box pb={5}>
                                <ul className="fp_meta mb0">
                                <li className="list-inline-item">
                                    <a href="#">
                                        {/* <small><del className="body-color">{price} UF</del></small><br /> */}
                                        <Heading as="span" fontSize={20} className="heading-color fw600">Cap Rate: {capRate() || '0.00'}</Heading>
                                    </a>
                                </li>
                            </ul>
                                </Box>
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    gap={2}
                                >
                                <Box
                                    backgroundColor="#FFF"
                                    borderRadius={'15px'}
                                    paddingRight={5}
                                >
                                <Text as="p"
                                    ml= '10px'
                                    mb= '5px!important'
                                >Tipologías</Text>

                                <UnorderedList className="prop_details mb0">
                                    <li className="list-inline-item">
                                        <a style={{
                                            display: 'flex',
                                        }} href="#">
                                            <span className="flaticon-bed pr5"></span>
                                            {dormitorios}D
                                        </a>
                                    </li>
          
    
                                    <li className="list-inline-item">
                                        <a href="#" style={{
                                            display: 'flex',
                                        }}>
                                            <span className="flaticon-ruler pr5"></span>
                                            {areaTotal} mts
                                        </a>
                                    </li>
                                </UnorderedList>
                                </Box>
                                <Box
                                    backgroundColor="#c8c8c8"
                                    borderRadius={'15px'}
                                    paddingRight={5}
                                >
                                <Text as="p"
                                    ml= '10px'
                                    mb= '5px!important'
                                >Cuotas desde</Text>
                                <UnorderedList
                                    
                                className="prop_details mb0">
                                    <li className="list-inline-item">
                                        <Heading
                                            fontSize={'20px!important'}
                                        as="a" >
                                         {milles(cuotas())} 
                                        </Heading>
                                    </li>
          
                                </UnorderedList>
                                </Box>
                                </Box>
                                
                            </Box>
                            </Link>
                            <Box 
                                mt={{
                                    base: "0px!important",
                                    md: "10px!important",
                                    lg: "0px!important",
                                    xl: "0px!important",
                                }}

                                //only mobile width
                                width={{
                                    base: "100%!important",
                                    md: "100%!important",
                                    lg: "25%!important",
                                    xl: "25%!important",
                                
                                }}    
            
                            className="wrapper2 text-center tal-md float-right fn-md">
                                <Box
                                    display={{
                                        base: "flex!important",
                                        md: "flex!important",
                                        lg: "flex!important",
                                        xl: "flex!important"

                                    }}
                                    flexDirection={{
                                        base: "row!important",
                                        md: "row!important",
                                        lg: "column!important",
                                        xl: "column!important"

                                    }}
                                    gap={2}
                                    mt={{
                                        base: "20px!important",
                                        md: "20px!important",
                                        lg: "0px!important",
                                        xl: "0px!important",
                                    }}
                                    justifyContent={'space-between'}
                                    h={{
                                        base: "auto!important",
                                        md: "auto!important",
                                        lg: '180px!important',
                                        xl: '180px!important'
                                    }}

                                >
                                <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        backgroundColor= "#595c5d"
                                        color= "white!important"
                                        borderRadius= "30px"
                                        padding= "10px"
                                        h={{
                                            base: "45px!important",
                                            md: "45px!important",
                                            lg: "45px!important",
                                            xl: "45px!important"
                                        }}
                                >
                                    <Text fontSize={'20px!important'} fontWeight={500} className="title" mb={'0px!important'} color={'white!important'}>UF {finalPrice} </Text></Box>
                                
                                <Button 
                                    onClick={() => setModal('cotizador', true)}
                                backgroundColor= "#FE5164"
    color= "white"
    borderRadius= "30px"
    font-size= "13px"
    font-weight= "600"
    height= "45px"
    textTransform= "uppercase"
    >Cotizar</Button>
    </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
            { modals.cotizador && <CotizarModal properties={properties} /> } 

            </>
            
 
  )
}

export default PropertyCard