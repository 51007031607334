import React from 'react'
import { NotFound } from '../components/404'

export const NotFoundPage = () => {
    return (
        <>
          <NotFound />  
        </>
    )
}
