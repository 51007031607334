

import { useState, useEffect } from 'react';

const useUF = () => {
    const [uf, setUF] = useState(0);

    useEffect(() => {
        fetch('https://mindicador.cl/api')
            .then(response => response.json())
            .then(data => setUF(data.uf.valor))
    }, []);
    return {
        uf,
    }




}


export default useUF;