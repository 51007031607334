import React, { useContext } from 'react';
import { Select } from '@chakra-ui/react'
import { PropsContext } from '../../context/PropsContext';


const EntregasDropdown = ({status, setStatus}) => {

  const { tipoEntrega, setTipoEntrega } = useContext(PropsContext);

  const handleChange = (e) => {
    setTipoEntrega(e.target.value)
    setStatus(!status)
  }







  return (

    <div style={{ display: "block" }} className="drop_content">
    <div className="dropdown bootstrap-select w100 show-tick">
      <div className="title">TIPO DE ENTREGA</div>
     
        <div className="filter-option">
          <div className="filter-option-inner">
            <div className="filter-option-inner-inner">{
            }</div>          
                   <Select onChange={handleChange}    placeholder='Tipo de entrega' variant='flushed' >
                    <option selected={tipoEntrega == 'Inmediata' && true  } value='Inmediata'>Entrega Inmediata</option>
                    <option selected={tipoEntrega == 'Futura' && true  }  value='Futura'>Entrega Futura</option>
                   </Select>

          </div>
        </div>

    </div>
  </div>
  );
};

export default EntregasDropdown;
