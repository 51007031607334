import React  from 'react';
import styles from './cotizarModal.module.css';
import Modal from '../Modal';
import Space from '../Space';
import Button from '../Button';
import useModal from '../../hooks/useModal';
// import Swal from 'sweetalert2'


const ImagesModal = ({children}) => {
//   const { setModal } = useModal();
//   const handleClose = () => setModal('selectionAvatars', false);






const { setModal } = useModal();
const handleClose = () => setModal('buydepaImages', false);

// const handleSubmit = () => {



//   Swal.fire({
//     title: 'Gracias por tu interés!',
//     text: 'En breve te llegará un correo con la información solicitada',
//     icon: 'success',
//     confirmButtonText: 'Ok'
//   })
//   handleClose()

// }


//click outside modal to close




  return (
    <Modal maxW={768}
    outsideClickClose= {'buydepaImages'}
    >
      <Space size={20} />
      <div className={styles.wrapper}>
    {children}
        <Space size={42} />
        <div className={styles.actionsBox}>
          <Button size='lg' onClick={handleClose}>
            Cerrar
          </Button>
          {/* <Button
            color='primary'
            size='lg'
            // disabled={() => {}}
            onClick={handleSubmit}
          >
            Enviar
          </Button> */}
        </div>
      </div>
    </Modal>
  );
};

export default ImagesModal;
