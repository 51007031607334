import React from 'react'
import { AboutText } from '../components/AboutText'
import { Brands } from '../components/Brands'
import { Breadcums } from '../components/Breadcums'
import { Team } from '../components/Team'
import { Testimonials } from '../components/Testimonials'

export const AboutPage = () => {

    return (
        <>
        <Breadcums currentPage="Sobre Nosotros"  />
        <AboutText />
        <Team />
        <Testimonials />
        <Brands />
        </>
    )
}
