import { Box, Link } from '@chakra-ui/react'
import React from 'react'
import { IoMailOpen, IoPerson } from "react-icons/io5";
import { Tooltip } from '@chakra-ui/react'

const FAB = ({ anchor, text, isPerson, isMail}) => {
  return (
    <Link href={anchor}>
        <Tooltip label={text}>

            <Box 
            position="fixed"
                bottom='20px'
                right={['16px', '44px']}
                zIndex={1}
                bg='#fe5164'
                borderRadius='50%'
                width= '50px'
                height= '50px'
                display= 'flex'
                justifyContent= 'center'
                alignItems= 'center'
                cursor={'pointer'}
            >
                {isMail && <IoMailOpen color={'white'} size={30}/>}
                {isPerson && <IoPerson color={'white'} size={30}/> }
            </Box>
            </Tooltip>
            </Link>
  )
}

export default FAB