import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';


import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';




export const PropertiesSlider = () => {

    const [properties, setProperties] = useState([])

    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getProperties = async () => {
            const res = await fetch("https://app.goodprop.cl/api/properties?populate=*&pagination[start]=0&pagination[limit]=-1&sort=createdAt:DESC");
            const json = await res.json();
            console.log(json?.data, 'propiedaddes home')
            setProperties(json?.data);
        }

        getProperties();
    }, [])


    console.log(properties, 'properties')

    return (
        <Swiper
        modules={[Navigation]}
        spaceBetween={50}
        slidesPerView={4}
        navigation
        breakpoints={
            {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 20
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 40
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 50
                },
                1280: {
                    slidesPerView: 4,
                    spaceBetween: 60
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 70
                },
                1600: {
                    slidesPerView: 4,
                    spaceBetween: 80
                },
            }
        }
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
          {
              properties.map((propiedad) => {
                  return (
                        <SwiperSlide key={propiedad?.attributes?.slug}>
                       <div className="item">
                                <div className="feat_property recent">
                                    <div className="thumb">
                                        <a href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                        <img className="img-whp" src={imgBaseURL+propiedad?.attributes?.featured_image?.data?.attributes?.url} alt="rp1.jpg" />
                                        <div className="thmb_cntnt">
                                            <ul className="tag mb0">
                                                <li className="list-inline-item">
                                                    {
                                                        propiedad?.attributes?.tags?.toString().split(",")[0] &&                                                     <a href={`/proyecto/${propiedad?.attributes?.slug}`}>{propiedad?.attributes?.tags?.toString().split(",")[0]}</a>

                                                    }
                                                </li>
                                            </ul>
                                            <ul className="tag2 mb0">   
                                                <li className="list-inline-item">
                                                    {
                                                        propiedad?.attributes?.tags?.toString().split(",")[1] &&                                                <a href={`/proyecto/${propiedad?.attributes?.slug}`}>{propiedad?.attributes?.tags?.toString().split(",")[1]}</a>

                                                    }
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="thmb_cntnt2">
                                            <ul className="listing_gallery mb0">
                                                <li className="list-inline-item">
                                                    <a className="text-white" href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-photo-camera mr5"></span>
                                                        {propiedad?.attributes?.fotos?.data.length}
                                                    </a>
                                                </li>
                                                {/* <li className="list-inline-item">
                                                    <a className="text-white" href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-play-button mr5"></span>
                                                        3
                                                    </a>
                                                </li> */}
                                            </ul>
                                        </div>
                                        </a>
                                    </div>
                                    <div className="details">
                                        <div className="tc_content">
        
                                            <h4>
                                                <a href="page-listing-single-v8.html">{propiedad?.attributes?.nombre}</a>
                                            </h4>
                                            <p>{propiedad?.attributes?.ciudad?.data?.attributes?.nombre}</p>
                                            <ul className="prop_details mb0">
                                                <li className="list-inline-item">
                                                    <a href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-bed pr5"></span>
                                                        <br /><p>{propiedad?.attributes?.Habitaciones}</p> </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-bath pr5"></span>
                                                        <br /><p>{propiedad?.attributes?.Bannos}</p> </a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-car pr5"></span>
                                                        <br /><p>{propiedad?.attributes?.estacionamientos} </p></a>
                                                </li>
                                                <li className="list-inline-item">
                                                    <a href={`/proyecto/${propiedad?.attributes?.slug}`}>
                                                        <span className="flaticon-ruler pr5"></span>
                                                        <br /><p>{propiedad?.attributes?.mt2}mt²</p>   </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="fp_footer">
                                            <ul className="fp_meta float-left mb0">
                                                <li className="list-inline-item">
                                                    <a href="">
                                                        <span className="heading-color fw600">Desde UF {propiedad?.attributes?.precio} Hasta UF {propiedad?.attributes?.Precio2} </span>
                                                    </a>
                                                </li>
                                            </ul>
                                                {/* <ul className="fp_meta float-right mb0">
                                                    <li className="list-inline-item">
                                                        <a className="icon" href="#">
                                                            <span className="flaticon-resize"></span>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="icon" href="#">
                                                            <span className="flaticon-add"></span>
                                                        </a>
                                                    </li>
                                                    <li className="list-inline-item">
                                                        <a className="icon" href="#">
                                                            <span className="flaticon-heart-shape-outline"></span>
                                                        </a>
                                                    </li>
                                                </ul> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                  )
              })
          }


                            </Swiper>
    )
}
