
//https://buydepa.com/stock-api/properties?filters[status][selling_status][$in][0]=Disponible&filters[status][selling_status][$in][1]=Reserva&filters[status][purchasing_status][$in][0]=Cierre%20y%20Copia&filters[status][purchasing_status][$in][1]=Cerrada%20para%20ingreso%20a%20CBR&filters[status][purchasing_status][$in][2]=Proceso%20de%20Inscripcion&filters[status][purchasing_status][$in][3]=Inscripcion%20Finalizada&filters[status][purchasing_status][$in][4]=Informe%20Final&filters[status][purchasing_status][$in][5]=Acta%20de%20Entrega&filters[status][purchasing_status][$in][6]=Proceso%20Finalizado&filters[status][desisted][$eq]=false&filters[property_group][id][$null]=true&[populate][0]=images.main_image_url&[populate][1]=typology&[populate][2]=selling_information&[populate][3]=internal_address&[populate][4]=subsidy&[populate][5]=status&sort=web_priority%3Aasc&publicationState=live&locale[0]=en&pagination[page]=1&pagination[pageSize]=1000
//bearer 2d78dc254522a08a92ed17298e081ae9a12b0e598e820e9133b6849c81790fe7b72a7fa78536452dbf7113905a77270c816d9cc54d37bfd08fc85dbf11b42b1c4f009d0ca39cf501d6fca0e275d0c378dd3ae72d279771dcdb84815942b6a6412df8f8ed0c5dbe06e5c763e5b434bd9b21c7c574233c1c494077d39e99a75f77

export const getAllDepas = async (minUF, maxUF, minCuota, maxCuota, bedroom, bathroom) => {

    const UF = minUF && maxUF ? `&filters[selling_information][final_selling_price][$gte]=${minUF}&filters[selling_information][final_selling_price][$lte]=${maxUF}` : '';
    const cuota = minCuota && maxCuota ? `&filters[selling_information][rent_price][$gte]=${minCuota}&filters[selling_information][rent_price][$lte]=${maxCuota}` : '';
    const dormitorio = bedroom ? `&filters[typology][bedrooms][$gte]=${bedroom}` : '';
    const baño = bathroom ? `&filters[typology][bathrooms][$gte]=${bathroom}` : '';
    try {

        //fetch
        const url = `https://buydepa.com/stock-api/properties?filters[status][selling_status][$in][0]=Disponible&filters[status][selling_status][$in][1]=Reserva&filters[status][purchasing_status][$in][0]=Cierre%20y%20Copia&filters[status][purchasing_status][$in][1]=Cerrada%20para%20ingreso%20a%20CBR&filters[status][purchasing_status][$in][2]=Proceso%20de%20Inscripcion&filters[status][purchasing_status][$in][3]=Inscripcion%20Finalizada&filters[status][purchasing_status][$in][4]=Informe%20Final&filters[status][purchasing_status][$in][5]=Acta%20de%20Entrega&filters[status][purchasing_status][$in][6]=Proceso%20Finalizado&filters[status][desisted][$eq]=false&filters[property_group][id][$null]=true&[populate][0]=images.main_image_url&[populate][1]=typology&[populate][2]=selling_information&[populate][3]=internal_address&[populate][4]=subsidy&[populate][5]=status&[populate][6]=area&sort=web_priority%3Aasc&publicationState=live&locale[0]=en&pagination[page]=1&pagination[pageSize]=1000${UF}${cuota}${dormitorio}${baño}`;
        const token = "2d78dc254522a08a92ed17298e081ae9a12b0e598e820e9133b6849c81790fe7b72a7fa78536452dbf7113905a77270c816d9cc54d37bfd08fc85dbf11b42b1c4f009d0ca39cf501d6fca0e275d0c378dd3ae72d279771dcdb84815942b6a6412df8f8ed0c5dbe06e5c763e5b434bd9b21c7c574233c1c494077d39e99a75f77"

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;

    } catch (error) {
        console.log(error)
        return null;
    }
}

export const getDepaBySlug = async (slug) => {

    try {

        //fetch
        const url = `https://buydepa.com/stock-api/properties?filters[status][selling_status][$in][0]=Disponible&filters[status][selling_status][$in][1]=Reserva&filters[status][purchasing_status][$in][0]=Cierre%20y%20Copia&filters[status][purchasing_status][$in][1]=Cerrada%20para%20ingreso%20a%20CBR&filters[status][purchasing_status][$in][2]=Proceso%20de%20Inscripcion&filters[status][purchasing_status][$in][3]=Inscripcion%20Finalizada&filters[status][purchasing_status][$in][4]=Informe%20Final&filters[status][purchasing_status][$in][5]=Acta%20de%20Entrega&filters[status][purchasing_status][$in][6]=Proceso%20Finalizado&filters[status][desisted][$eq]=false&filters[property_group][id][$null]=true&[populate][0]=images.main_image_url&[populate][1]=typology&[populate][2]=selling_information&[populate][3]=internal_address&[populate][4]=subsidy&[populate][5]=status&populate[6]=area&populate[7]=images.slides&populate[8]=amenities&populate[9]=coordinates&sort=web_priority%3Aasc&publicationState=live&locale[0]=en&filters[slug][$eq]=${slug}&pagination[page]=1&pagination[pageSize]=1000`
        // const url = `https://buydepa.com/stock-api/properties?populate=*&filters[slug][$eq]=${slug}&pagination[page]=1&pagination[pageSize]=1000`;
        const token = "2d78dc254522a08a92ed17298e081ae9a12b0e598e820e9133b6849c81790fe7b72a7fa78536452dbf7113905a77270c816d9cc54d37bfd08fc85dbf11b42b1c4f009d0ca39cf501d6fca0e275d0c378dd3ae72d279771dcdb84815942b6a6412df8f8ed0c5dbe06e5c763e5b434bd9b21c7c574233c1c494077d39e99a75f77"

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;

    } catch (error) {
        console.log(error)
        return null;
    }
}

export const getDepaById = async (id) => {
    try {
        const url = `https://buydepa.com/stock-api/properties/${id}?filters[status][selling_status][$in][0]=Disponible&filters[status][selling_status][$in][1]=Reserva&filters[status][purchasing_status][$in][0]=Cierre%20y%20Copia&filters[status][purchasing_status][$in][1]=Cerrada%20para%20ingreso%20a%20CBR&filters[status][purchasing_status][$in][2]=Proceso%20de%20Inscripcion&filters[status][purchasing_status][$in][3]=Inscripcion%20Finalizada&filters[status][purchasing_status][$in][4]=Informe%20Final&filters[status][purchasing_status][$in][5]=Acta%20de%20Entrega&filters[status][purchasing_status][$in][6]=Proceso%20Finalizado&filters[status][desisted][$eq]=false&filters[property_group][id][$null]=true&[populate][0]=images.main_image_url&[populate][1]=typology&[populate][2]=selling_information&[populate][3]=internal_address&[populate][4]=subsidy&[populate][5]=status&[populate][6]=area&sort=web_priority%3Aasc&publicationState=live&locale[0]=en&pagination[page]=1&pagination[pageSize]=1000`;
        const token = "2d78dc254522a08a92ed17298e081ae9a12b0e598e820e9133b6849c81790fe7b72a7fa78536452dbf7113905a77270c816d9cc54d37bfd08fc85dbf11b42b1c4f009d0ca39cf501d6fca0e275d0c378dd3ae72d279771dcdb84815942b6a6412df8f8ed0c5dbe06e5c763e5b434bd9b21c7c574233c1c494077d39e99a75f77"

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.log(error)
        return null;
    }


}