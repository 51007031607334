import React from 'react'

export const Share = () => {
    return (
        <div style={{ display: 'none'}} className="spss style2 mt30">
        <ul className="mb0">
          <li className="list-inline-item icon"><a href="#"><span className="flaticon-share"></span></a></li>
          <li className="list-inline-item"><a href="#">Share</a></li>
          <li className="list-inline-item icon"><a href="#"><span className="flaticon-heart-shape-outline"></span></a></li>
          <li className="list-inline-item"><a href="#">Save</a></li>
        </ul>
      </div>
    )
}
