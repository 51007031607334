import React from 'react'
import {
    Link,
} from "react-router-dom";
import PropTypes from 'prop-types';



export const Breadcums = ({ currentPage }) => {

    return (
        <section className="inner_page_breadcrumb">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="breadcrumb_content">
                            <h2 className="breadcrumb_title">{currentPage}</h2>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/">Inicio</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{currentPage}</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

Breadcums.propTypes = {
    currentPage: PropTypes.any,
};