import { Box, Heading, Hide, Show } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useModal from '../../../../hooks/useModal'
import ImagesModal from '../../../ImagesModal'
import FsLightbox from 'fslightbox-react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';



const Header = (depa) => {

    //function to delete url https://buydepa-media.s3.amazonaws.com/products
    const deleteUrl = (url) => {
        return url.replace('https://buydepa-media.s3.amazonaws.com/products', '')
    }

    console.log(deleteUrl('https://buydepa-media.s3.amazonaws.com/products/723/723_17_410141f7d6'), 'deleteUrl')

    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
        });
  
        function openLightboxOnSlide(number) {
          setLightboxController({
          toggler: !lightboxController.toggler,
          slide: number
          });
          }


    const { address, commune, garages, antiquity, type } = depa.attributes
    const { bathrooms, bedrooms,  } = depa.attributes.typology
    const { area_total } = depa.attributes.area
    const { final_selling_price, selling_price } = depa.attributes.selling_information
    const { unit } = depa.attributes.internal_address
    const { url } = depa.attributes.images[0]?.main_image_url?.data.attributes || 'https://buydepa.com/default_image.svg'
    const { data: slidesData } = depa.attributes.images[0].slides

    const slides = slidesData?.slice(0, 12)
    const diff = slidesData?.length - slides?.length

    const { modals, setModal } = useModal();

    const handleOpen = () => setModal('buydepaImages', true);


  return (
    <section className="listing-title-area pb50" >
    <div className="container">
        <div className="row mb30">
            <div className="col-lg-7 col-xl-7">
                <div className="single_property_title mt30-767">
                    <div className="breadcrumb_content style3">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/">Inicio</Link></li>
                            <li className="breadcrumb-item"><Link to={'/seminuevos'}> Seminuevos </Link>  </li>
                            <li className="breadcrumb-item active style2" aria-current="page">{address}</li>
                        </ol>
                    </div>
                    <div className="media">
                        <div className="media-body">
                            <Hide above="md">
                                <Heading as="h1" size="lg" color="primary.500" fontWeight="bold" mt="1rem" mb="1rem">{`${address}, ${type} ${unit}`}</Heading>
                            </Hide>
                            <Show above='md' >
                            <Heading as="h1" size="lg" color="primary.500" fontWeight="bold" mt="1rem" >{`${address}, ${type} ${unit}`}</Heading>
                            <p>{commune}</p>
                            <ul className="prop_details mb0">
                                <li className="list-inline-item mr20">
                                    <a className="mr20" href="#"><span className="flaticon-bed pr5 vam"></span>{bedrooms} Dormitorios</a>
                                </li>
                                <li className="list-inline-item mr20">
                                    <a className="mr20" href="#"><span className="flaticon-bath pr5 vam"></span> { bathrooms} Baños</a>
                                </li>
                                <li className="list-inline-item mr20">
                                    <a className="mr20" href="#"><span className="flaticon-car pr5 vam"></span> {garages} Bodega</a>
                                </li>
                                <li className="list-inline-item mr20">
                                    <a className="mr20" href="#"><span className="flaticon-ruler pr5 vam"></span> {area_total} m²</a>
                                </li>
                                <li className="list-inline-item mr20">
                                    <a href="#"><span className="flaticon-calendar pr5 vam"></span> Año construcción: {antiquity}</a>
                                </li>
                            </ul>
                            </Show>
                        </div>
                    </div>
                </div>
            </div>
            <Show above='md' >

            <div className="col-lg-5 col-xl-5">
                <div className="single_property_social_share_content text-right tal-md">
                    <div className="spss style2 mt30">
                        <ul className="mb0">
                            <li className="list-inline-item ">
                            </li>
                            <li className="list-inline-item"></li>
                            <li className="list-inline-item ">
                            </li>
                            <li className="list-inline-item"></li>
                        </ul>
                    </div>
                    <div className="price mt20 mb10">
                        <h3>
                            <small className="mr10"><del>{Math.ceil(selling_price)} UF</del></small> <span
                                style={{
                                    color: '#EF6667',
                                    fontWeight: 'bold',
                                    fontSize: '1.5rem'
                                }}
                            >{Math.ceil(final_selling_price)} UF</span>
                        </h3>
                    </div>
                    <p className="mb0" style={{
                        fontSize: '1.1rem'

                    }}>Entrega inmediata</p>

                </div>
            </div>
            </Show>
        </div>

        <Hide above='md' >
                <Swiper
                modules={[Navigation, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      navigation 
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
        {slidesData ? (slidesData.map((slide, index) => (
            <SwiperSlide key={index}>
                <img src={slide.attributes.url} alt="slide" className="img-fluid" style={{
                    borderRadius: '10px',
                }} />
            </SwiperSlide>
        ))) :
            <SwiperSlide>
                <img src={url} alt="slide" className="img-fluid" style={{
                    borderRadius: '10px',
                }} />
            </SwiperSlide>
        }
      
    </Swiper>
    </Hide>

    <Show above='md' >
        <div className="row">
            <div className="col-lg-7">
                <div className="row">
                    <div className="col-lg-12 pr0 pl15-767 pr15-767">
                        <div className="spls_style_two mb30-md">
                            <a className="popup-img">
                                <img className="img-fluid" src={url} alt="1.jpg"  style={{
                                    borderTopLeftRadius: '20%',
                                    borderBottomRightRadius: '20%',
                                
                                }}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-5">
                <div className="row">
                  


                  {
                    modals.buydepaImages && ( <>
                    <ImagesModal>
                                                                               <Box 
                                display="grid"
                                gridTemplateColumns="repeat(3, 1fr)"
                                gridGap={6}
                                >
                        {
     
                            slidesData && slidesData.map((slide, index) => (

                                <div                                 key={index}  className="">
                                <div className="spls_style_two mb10">
                                    <a className="popup-img" onClick={() => openLightboxOnSlide(index+1)}>
                                        <img className="img-fluid w100" src={slide.attributes.url} alt="1.jpg"   />
                                    </a>
                                    </div>
                                </div>
                               
                            ))

                        }
                         </Box>
                        
                        </ImagesModal>
                        <FsLightbox
toggler={lightboxController.toggler}
sources={slidesData.map(slide => 'https://goodprop.onrender.com/api/products'+deleteUrl(slide.attributes.url))}
slide={lightboxController.slide}

/>
                        </> 


                         )
                  }



                    {
    slides &&  slides.map((slide, index) => {
        
        if (index === slides.length -1) {
            console.log(slides.length -1, 'into if')
            const last = slides[slides.length -1]
            return (
                <div key={index} className="col-sm-4 col-lg-4 pr5 pr15-767">
                    <div className="spls_style_two mb10">
                        <a className="popup-img" style={{ cursor: 'pointer'}} onClick={handleOpen}>
                            <img className="img-fluid w100" src={last?.attributes?.url} alt="1.jpg" />
                        </a>
                        {
                            diff > 0 && (
                                <div className="overlay popup-img">
                                                            <a className="popup-img" style={{ cursor: 'pointer'}} onClick={handleOpen}>

                  <h3 className="title">+{diff}</h3>
                  </a>
                </div>
                            )
                        }
                        
                    </div>  
                </div>



            )
        }else {
        return (
            <div key={index} className="col-sm-4 col-lg-4 pr5 pr15-767">
                <div className="spls_style_two mb10">
                <a className="popup-img" style={{ cursor: 'pointer'}} onClick={handleOpen}>
                        <img className="img-fluid w100" src={slide.attributes.url} alt="1.jpg" />
                    </a>
                    </div>
                </div>
        )
    }
    })

}
  
                </div>
            </div>
        </div>
    </Show>
    </div>
</section>

  )
}

export default Header