import React from 'react';
import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Box,
  Text,
  Heading,
} from '@chakra-ui/react'
import styles from './cotizarModal.module.css';
import Modal from '../Modal';
import Space from '../Space';
import Button from '../Button';
import useModal from '../../hooks/useModal';
import Swal from 'sweetalert2'
import { createCotizacion } from '../../api/cotizaciones';
//import { uuid } from 'uuidv4';
import { v4 as uuidv4 } from 'uuid';
import useRut from '../../hooks/useRut';

const CotizarModalDetails = (depa) => {

  console.log(depa.depa.id, 'depas111')

  const idString = depa.depa.id.toString()

  let date = new Date()

  let day = date.getDate()
  let month = date.getMonth() + 1
  let year = date.getFullYear()

  const [rut, valido, setRut] = useRut();


//   const { setModal } = useModal();
//   const handleClose = () => setModal('selectionAvatars', false);
const [form, setForm] = React.useState({
    nombre: '',
    email: '',
    telefono: '',
})





const sendEmail = async (mail, name, id, uuid ) => {
  const res = await fetch('https://app.goodprop.cl/api/email/', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          to: mail,
          from: 'prospectos@goodprop.cl',
          subject: 'Cotización GoodProp',
          html: `<h1>¡Hola! ${name}</h1>
          <p>Has cotizado: ${depa.depa.attributes?.address}.</p>
          <p>Para ver la cotización, ingresa a <a href="https://goodprop.cl/cotizacion/${id}/${uuid}">goodprop</a>.</p>
          <p>¿Quieres ver el listado de tus cotizaciones? Ingresa a <a href="https://goodprop.cl/cotizacion/user/${mail}">mis cotizaciones</a>.</p>
          <p>¡Gracias por cotizar con nosotros!</p>
          <p>Equipo GoodProp</p>`
      })
  })
  const json = await res.json();
  console.log(json)
}

const { setModal } = useModal();
const handleClose = () => setModal('cotizador', false);

const handleSubmit = async () => {

  if(!valido) {
    alert('Rut inválido')
    return
  }

  const response = await createCotizacion(
    // uuid(),

     uuidv4(),
     form.nombre,
     rut,
     form.telefono,
     form.email,
     idString,
     `${day}/${month}/${year}`,

  )



  if(response?.data?.attributes?.uuid) {



    console.log('success')


  sendEmail(form.email, form.nombre, response.data.id, response.data.attributes.uuid)
    Swal.fire({
    title: 'Gracias por tu interés!',
    text: 'En breve te llegará un correo con la información solicitada',
    icon: 'success',
    confirmButtonText: 'Ok'
  })
  handleClose()
  }


  

}

const handleChangeInput = (e) => {
  const { name, value } = e.target;
  //setState((prevState) => ({ ...prevStatestate, [name]: value }));
  setForm((prevState) => ({ ...prevState, [name]: value }));

};



  return (
    <Modal maxW={768}>
      <Space size={20} />
      <div className={styles.wrapper}>
      <Box>
        <Heading as="h3" size="lg" color="primary.500">
          Cotizar departamento {depa?.depa?.address}
        </Heading>
  <Text>
    Al completar los campos enviaremos la cotización a tu correo.
  </Text>
</Box>
<Space size={20} />

      <FormControl>
  <FormLabel>Nombre</FormLabel>
  <Input type='text' name="nombre" onChange={(e) => handleChangeInput(e)} />
</FormControl>
<Space size={20} />

<FormControl>
  <FormLabel>RUT</FormLabel>
  <Input type='text' name="rut" value={rut} onChange={(e) => setRut(e.target.value)} />
</FormControl>
<Space size={20} />

      <FormControl>
  <FormLabel>Email address</FormLabel>
  <Input type='email' name="email" onChange={(e) => handleChangeInput(e)} />
  <FormHelperText>Nunca compartiremos tu correo.</FormHelperText>
</FormControl>

<Space size={20} />

<FormControl>
  <FormLabel>Teléfono</FormLabel>
  <Input type='text' name="telefono" onChange={(e) => handleChangeInput(e)} />
</FormControl>

<FormControl>
<Space size={20} />
{/* 
<Select icon={loading ? <ImSpinner3 /> : <AiFillCaretDown /> } name="property" onChange={(e) => handleChangeInput(e)} >
  <option selected disabled value="NN">{loading ? 'Cargando departamentos...' : "Selecciona el departamento a cotizar"}</option>
{
  properties.map((property) => {
    return <option key={property.id} value={property.id}>{property.attributes.address}</option>
  })
}
</Select> */}
</FormControl>





        <Space size={42} />
        <div className={styles.actionsBox}>
          <Button size='lg' onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            color='primary'
            size='lg'
            // disabled={() => {}}
            onClick={() => handleSubmit()}
          >
            Enviar
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CotizarModalDetails;
