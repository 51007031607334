import React, { useEffect } from 'react'
import GeneratePDF from '../components/GeneratePDF';
import { useParams } from 'react-router';
import { getCotizacionById } from '../api/cotizaciones';

const CotizacionPage = () => {
    const { slug, uuid } = useParams();
    const [cotizacion, setCotizacion] = React.useState({})
    console.log(slug, uuid)

    const getCotizacion = async () => {
        const response = await getCotizacionById(slug)
        setCotizacion(response.data)
    }

    useEffect(() => {
        getCotizacion()
    }, [])



  return (
    <section>

        <GeneratePDF  cotizacion={cotizacion} />
    </section>

  )
}

export default CotizacionPage