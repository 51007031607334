import React, { createContext, useState } from 'react';

export const PropsContext = createContext({});



export const PropsProvider = ({ children }) => {


    const [tipoEntrega, setTipoEntrega] = useState('');
    const [habitaciones, setHabitaciones] = useState('1');
    const [comuna, setComuna] = useState('');




    return (
        <PropsContext.Provider value={{ tipoEntrega, habitaciones, comuna, setComuna, setHabitaciones, setTipoEntrega   }}>
            {children}
        </PropsContext.Provider>
    )

}