import React, { useEffect, useState } from 'react'
import axios from 'axios'
import HomeSeach from '../HomeSearch'
import { Link } from 'react-router-dom'
import { Show } from '@chakra-ui/react'

export const Hero = () => {

    


    const imgBaseURL = 'https://app.goodprop.cl'

    const [props, setProps] = useState([])
    const [text, setText] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [selectCity, setSelectCity] = useState('all')
    const [loading, setLoading] = useState(true)
    // const [selectValue, setSelectValue] = useState('')
    useEffect(() => {

        const loadUsers = async () => {
            const response = await axios.get('https://app.goodprop.cl/api/properties?populate=*&pagination[start]=0&pagination[limit]=-1');
            setProps(response.data.data)
            setLoading(false)
        }

        loadUsers()
    }, [])




    //Listar nombre de las propiedades
    const listProps = props.map(props => {
        return {
            'id': props.id,
            'name': props?.attributes?.nombre,
            'price': props?.attributes?.precio,
            'city': props?.attributes?.ciudad?.data?.attributes?.nombre,
            'img': imgBaseURL + props?.attributes?.featured_image?.data?.attributes?.url,
            'bathrooms': props?.attributes?.Bannos,
            'bedrooms': props?.attributes?.Habitaciones,
            'garage': props?.attributes?.estacionamientos,
            'area': props?.attributes?.mt2,
            'slug' : props?.attributes?.slug,
        }
    })

    const listCities = props.map(props => {
        return {
            'id': props.id,
            'name': props?.attributes?.ciudad?.data?.attributes?.nombre,
        }
    })



    //eliminar duplicados
    const uniqueCities = [...new Set(listCities.map(item => item.name))]


    //filtrar listProps por una ciudad
    const filterProps = (city) => {
        return listProps.filter(props => {
            return props.city === city
        })
    }



 
    // const onSelectHandler = (e) => {
    //     const value = e.target.value
    //     setSelectCity(value)
    //     console.log(value, 'value')
    // }

    console.log(selectCity, 'selectCity', setSelectCity)



    const onChangeHandler = (text) => {
        let matches = [];

        if (selectCity && text.length > 0) {
            if (selectCity === 'all') {
                matches = listProps.filter(props => {
                    const regex = new RegExp(`${text}`, 'gi');
                    return props.name.match(regex) || props.city.match(regex) || props.price.match(regex)
                })
                setSuggestions(matches)
                setText(text)
            } else {
                matches = filterProps(selectCity).filter(props => {
                    const regex = new RegExp(`${text}`, 'gi');
                    return props.name.match(regex) || props.city.match(regex) || props.price.match(regex)
                })
                setSuggestions(matches)
                setText(text)
            }
        } else if (text.length > 0) {
            matches = listProps.filter(props => {
                const regex = new RegExp(`${text}`, 'gi');
                return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) 
            })
            setSuggestions(matches)
            setText(text)
        } else {
            setSuggestions([])
            setText('')
        }
    }
   

    if (loading) {
        console.log('loadingf')
    }


    
    return (
        <section className="home-one home1-overlay bg-home4" >
            <div className="container">
                <div className="row posr">
                    <div className="col-lg-12">
                        <div className="home_content home1 custom_width home4 respMOB">
                            <div className="home-text text-center">
                                <p className="fz16 color-white">&quot;El mejor momento para invertir fue hace 10 años, el segundo mejor es hoy.&quot;</p>
                                <h2 className="responsiveFont">Encuentra tu futura inversión</h2>
                            </div>
                            <div className="home_tabs home4">
                                <ul className="nav justify-content-center nav-tabs" id="myTab2" role="tablist">
                                </ul>
                                <div className="tab-content" id="myTabContent2">

                                    <div className="tab-pane fade show active" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                        <div className="home_adv_srch_opt home4">
                                            <div className="wrapper">
                                                <div className="home_adv_srch_form home4">

                    <HomeSeach onChangeHandler={onChangeHandler} text={text} uniqueCities={uniqueCities}  />
{/* 
                    <input
                                                                            style={{ width: '100%', border: 'none' }}
                                                                            type="search"
                                                                            className="form-control"
                                                                            placeholder="Buscar..."
                                                                            onChange={e => onChangeHandler(e.target.value)}
                                                                            value={text}
                                                                        /> */}

<Show         breakpoint='(min-width: 724px)'>
<div className="search__autocomplete">
                                                                {suggestions && suggestions.map((suggestion, index) => (
                                                                    <>
                                                                        <div key={index} className="feature-item--wrapper">
                                                                            <div className="img-wrapper">
                                                                                <img className='img__wrapper' src={suggestion.img} width="64" />
                                                                            </div>
                                                                            <div className="text">
                                                                                <Link to={`proyecto/${suggestion.slug}`}>
                                                                                    <strong className="feature-title">{suggestion.name}</strong>
                                                                                    <p className="feature-description"><small>{suggestion.city}</small></p>
                                                                                    <p className="feature-description">Desde <b>{suggestion.price}</b>   UF</p>
                                                                                </Link>
                                                                            </div>
                                                                            <div className='search_icons'>
                                                                                <ul className="prop_details mb0 search_icons_props">
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-bed pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.bedrooms}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-bath pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.bathrooms}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-car pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.garage}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-ruler pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.area}mt²</p>

                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <Link to={`proyecto/${suggestion.slug}`}>
                                                                                            <span className='pr5' >

                                                                                                <i style={{ fontSize: '20px', marginTop: 30, color: '#f03348' }} className="fa fa-external-link"></i>
                                                                                            </span>

                                                                                        </Link>
                                                                                    </li>

                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                                }
                                                            </div>
                                                            </Show>


                                                    {/* <form className="bgc-white">
                                                        <div className="form-row align-items-center">
                                                            <div style={{ paddingLeft: '10px' }} className="col-auto home_form_input mb15-md">
                                                                <div className="form-group style2 mb-0">
                                                                    <label>TIPO</label>
                                                                    <div className="container_select">
                                                                        <select className="select__hero">
                                                                            <option value="Apartment">Departamento</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto home_form_input mb15-md">
                                                                <div className="form-group style2 mb-0">
                                                                    <label>UBICACIÓN</label>
                                                                    <div className="container_select">
                                                                        <select
                                                                            className="select__hero"
                                                                            disabled={false}
                                                                            value={selectCity}
                                                                            onChange={e => onSelectHandler(e)}
                                                                        >
                                                                            <option value="all">Todas las ciudades</option>
                                                                            {
                                                                                uniqueCities.map(city => {
                                                                                    return (
                                                                                        <option key={city} value={city}>{city}</option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto home_form_input mb15-md">
                                                                <div className="form-group style1 mb-0">
                                                                    <div className="select-wrap style1-dropdown">
                                                                        <input
                                                                            style={{ width: '100%', border: 'none' }}
                                                                            type="search"
                                                                            className="form-control"
                                                                            placeholder="Buscar..."
                                                                            onChange={e => onChangeHandler(e.target.value)}
                                                                            value={text}
                                                                        />

                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-auto home_form_input2">
                                                                <button type="submit" className="btn search-btn ml15 ml0-991"><span className="fa fa-search"></span> BUSCAR</button>
                                                            </div>


                                                            <div className="search__autocomplete">
                                                                {suggestions && suggestions.map((suggestion, index) => (
                                                                    <>
                                                                        <div key={index} className="feature-item--wrapper">
                                                                            <div className="img-wrapper">
                                                                                <img className='img__wrapper' src={suggestion.img} width="64" />
                                                                            </div>
                                                                            <div className="text">
                                                                                <Link to={`proyecto/${suggestion.slug}`}>
                                                                                    <strong className="feature-title">{suggestion.name}</strong>
                                                                                    <p className="feature-description"><small>{suggestion.city}</small></p>
                                                                                    <p className="feature-description">Desde <b>{suggestion.price}</b>   UF</p>
                                                                                </Link>
                                                                            </div>
                                                                            <div className='search_icons'>
                                                                                <ul className="prop_details mb0 search_icons_props">
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-bed pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.bedrooms}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-bath pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.bathrooms}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-car pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.garage}</p>
                                                                                    </li>
                                                                                    <li className="list-inline-item">
                                                                                        <span style={{ fontSize: '32px' }} className="flaticon-ruler pr5"></span><br />
                                                                                        <p className='p__centered'>{suggestion.area}mt²</p>

                                                                                    </li>

                                                                                    <li className="list-inline-item">
                                                                                        <Link to={`proyecto/${suggestion.slug}`}>
                                                                                            <span className='pr5' >

                                                                                                <i style={{ fontSize: '20px', marginTop: 30, color: '#f03348' }} className="fa fa-external-link"></i>
                                                                                            </span>

                                                                                        </Link>
                                                                                    </li>

                                                                                </ul>

                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))
                                                                }
                                                            </div>

                                                        </div>
                                                    </form> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    )
}
