import React, { createContext, useState } from 'react';

export const EntregaContext = createContext();
export const BedContext = createContext();

const initialStateEntrega = {
    value: 'none',
  };
  const initialStateBed = {
    value: 'none',
  };



export const EntregaProvider =  ({children}) => {
    const [entrega, setEntrega] = useState(initialStateEntrega);


    return (
        <EntregaContext.Provider value={{entrega, setEntrega}}>
            {children}
        </EntregaContext.Provider>
    )
}

export const BedProvider =  ({children}) => {
    const [habitacion, setHabitacion] = useState(initialStateBed);


    return (
        <BedContext.Provider value={{habitacion, setHabitacion}}>
            {children}
        </BedContext.Provider>
    )
}