import React from 'react';

export const Features = () => {
  return (
    <div style={{ display: 'none'}} className="additional_details pb40 mt50 bb1">
    <div className="row">
      <div className="col-lg-12">
        <h4 className="mb30">Caracteristicas</h4>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-air-conditioner"></span></div>
          <div className="details">
            <div className="title">Air Conditioning</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-grass"></span></div>
          <div className="details">
            <div className="title">Lawn</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-refrigerator"></span></div>
          <div className="details">
            <div className="title">Refrigerator</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-liquid-soap"></span></div>
          <div className="details">
            <div className="title">Washer</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-barbecue"></span></div>
          <div className="details">
            <div className="title">Barbeque</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-laundry"></span></div>
          <div className="details">
            <div className="title">Laundry</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-sauna"></span></div>
          <div className="details">
            <div className="title">Sauna</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-wifi"></span></div>
          <div className="details">
            <div className="title">WiFi</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-dryer"></span></div>
          <div className="details">
            <div className="title">Dryer</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-microwave"></span></div>
          <div className="details">
            <div className="title">Microwave</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-swimmer"></span></div>
          <div className="details">
            <div className="title">Swimming Pool</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-blinds"></span></div>
          <div className="details">
            <div className="title">Window Coverings</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-dumbbell"></span></div>
          <div className="details">
            <div className="title">Gym</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-outdoor-shower"></span></div>
          <div className="details">
            <div className="title">Outdoor Shower</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-coaxial"></span></div>
          <div className="details">
            <div className="title">TV Cable</div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-xl-3">
        <div className="listing_feature_iconbox mb30">
          <div className="icon float-left mr10"><span className="flaticon-chair"></span></div>
          <div className="details">
            <div className="title">Dining room</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};
