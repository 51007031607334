import { baseURL } from "./endpoints"


export const createCotizacion = async (uuid, nombre, rut, telefono, email, buydepaid, fecha) => {

    try {
        const url = `${baseURL}/cotizacions`

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "data": {
                    uuid,
                    nombre,
                    rut,
                    telefono,
                    email,
                    buydepaid,
                    fecha
                }
            })
        })

        const data = await response.json()
        return data


    } catch (error) {
        console.log(error)
        return null
    }

}

export const getCotizacionById = async (id) => {

    try {
        const url = `${baseURL}/cotizacions/${id}`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }
}

export const getCotizacionByEmail = async (email) => {

    try {
        const url = `${baseURL}/cotizacions?filters[email][$eq]=${email}`

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const data = await response.json()
        return data
    } catch (error) {
        console.log(error)
        return null
    }


}
