import React from 'react'

import error from '../../assets/error.png'

export const NotFound = () => {
    return (
        <section className="our-error">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1 text-center">
                        <div className="error_page footer_apps_widget">
                            <img className="img-fluid" src={error} alt="error.png" />
                            <div className="erro_code"><h2>Ohh! Página no encontrada</h2></div>
                            <p>Parece que no podemos encontrar la página que estás buscando</p>
                            <form className="form-inline mailchimp_form">
                                <label className="sr-only" htmlFor="inlineFormInputName">Nombre</label>
                                <input type="text" className="form-control mb-2 mr-sm-2" id="inlineFormInputName" placeholder="Escribe tu correo..." />
                                <button type="submit" className="btn btn-primary mb-2"><span className="flaticon-email"></span></button>
                            </form>
                        </div>
                        <a className="btn_error text-thm" href="/">Volver al Inicio</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
