import React from 'react'
import { Hero } from '../components/Hero';
import { HomeParallaxForm } from '../components/HomeParallaxForm';
import { Properties } from '../components/Properties';
import { Cities } from '../components/Cities';
import FAB from '../components/FAB/FAB';
export const HomePage = () => {
    return (
        <>
      <Hero />
      <Cities />
      <Properties />
      {/* <ExploreProperties />
      <Blog /> */}
      <HomeParallaxForm />  
      <FAB anchor="#contact" text="Asesoría gratuita" isMail />

        </>
    )
}
