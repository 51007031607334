import React from 'react'
import { Breadcrums } from './Breadcrums';
import { PropsHeader } from './PropsHeader';
import { Price } from './Price';
import { Share } from './Share';
import { ImagesProperty } from './ImagesProperty';



export const ListingSingleProperty = () => {
    return (
  <>
        <section className="listing-title-area pb50">
            <div className="container">
            <div className="row mb30">
                <div className="col-lg-7 col-xl-7">
                <div className="single_property_title mt30-767">
                    <Breadcrums />
                    <PropsHeader />
                </div>
                </div>
                <div className="col-lg-5 col-xl-5">
                <div className="single_property_social_share_content text-right tal-md">
                    <Share />
                    <Price  />          
                    </div>
                </div>
            </div>
            <ImagesProperty />
            </div>
        </section>
  </>
    )
}
