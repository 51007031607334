import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Swal from 'sweetalert2'

const AgentProfile = () => {
    const { slug } = useParams(); 
    
    const [property, setProperty] = useState(null);
    const [name, setName] = useState(null);

    const [broker, setBroker] = useState([])
    const [form, setForm] = useState({
        nombre: '',
        correo: '',
        telefono: '',
        mensaje: 'Hola, estoy interesado en '
    })




    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getProperty = async () => {
            const res = await fetch(`https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}&populate=broker`);
            const json = await res.json();
            setName(json?.data[0]?.attributes?.nombre);

            setProperty(json?.data[0]?.attributes?.broker?.data?.id);
        }

        getProperty();
    }, [])


    console.log(property)
    
    useEffect(() => {
        const getBroker = async () => {
            const res = await fetch(`https://app.goodprop.cl/api/brokers/${property}?populate=foto`);
            const json = await res.json();
            setBroker(json?.data?.attributes);
        }

        getBroker();
    }, [property])

console.log(broker, 'broker', name)


const { nombre, correo, telefono, mensaje } = form;

const url = window.location.href;

const sendEmail = async () => {
    const res = await fetch('https://app.goodprop.cl/api/email/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            to : 'prospectos@goodprop.cl',
            from : 'prospectos@goodprop.cl',
            subject : name,
            html : `<bold>Hola ${broker.nombre}</bold> <br> Ha llegado una nueva solicitud de contacto desde ${url} <br> <br> <b>Nombre:</b> ${nombre} <br> <b>Correo:</b> ${correo} <br> <b>Telefono:</b> ${telefono} <br> <b>Mensaje:</b> ${mensaje}`
})
    })
    const json = await res.json();
    console.log(json)
}

const buttonSubmit = (e) => {
    e.preventDefault();


    if(nombre === '' || correo === '' || telefono === '' || mensaje === '') {
      Swal.fire({
        title: 'Error!',
        text: 'Todos los campos son obligatorios',
        icon: 'error',
        confirmButtonText: 'OK'
      })
      
        return;
    }

    if (nombre.length < 3) {
        Swal.fire({
            title: 'Error!',
            text: 'El nombre debe tener al menos 3 caracteres',
            icon: 'error',
            confirmButtonText: 'OK'
          })
          return;
    }

    if (correo.length < 3) {
        Swal.fire({
            title: 'Error!',
            text: 'Ingresa un correo válido',
            icon: 'error',
            confirmButtonText: 'OK'
          })
          return;
    }

    if (telefono.length < 8) {
        Swal.fire({
            title: 'Error!',
            text: 'El telefono es incorrecto ',
            icon: 'error',
            confirmButtonText: 'OK'
          })
          return;
    }

    if (mensaje.length < 3) {
        Swal.fire({
            title: 'Error!',
            text: 'El mensaje es muy corto',
            icon: 'error',
            confirmButtonText: 'OK'
          })
          return;
    }

    Swal.fire({
        title: '¿Estas seguro?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, enviar!'
      }).then((result) => {
        if (result.value) {
          sendEmail();
          setForm({
            nombre: '',
            correo: '',
            telefono: '',
            mensaje: ''
        })
        }
      })
}
   

    // get input values

    const handleChange = (e) => {
      
      e.target.name === 'nombre' ? setForm({...form, nombre: e.target.value}) :
      e.target.name === 'correo' ? setForm({...form, correo: e.target.value}) :
      e.target.name === 'telefono' ? setForm({...form, telefono: e.target.value}) :
      e.target.name === 'mensaje' ? setForm({...form, mensaje: e.target.value}) :
      null


    }






    const phone = broker?.telefono?.replace(/\+/g, '');

    return (
    <div className="col-lg-4 col-xl-3">
    <div className="listing_single_sidebar">
      <div className="sidebar_agent_search_widget mb30">
        <div className="media">
          <img className="mr-3 author_img" src={imgBaseURL+broker?.foto?.data?.attributes?.url} alt="author.png" />
          <div className="media-body">
            <h5 className="mt10 mb5 fz16 heading-color fw600">{broker?.nombre}</h5>
            <p className="mb0">{broker?.telefono}</p>
          </div>
        </div>
        <div className="agent_search_form">
          <form >
            <div className="form-group input-group mb30">
              <input type="text" className="form-control form_control" name="nombre" value={nombre} onChange={(e) => handleChange(e)} placeholder="Nombre" />
            </div>
            <div className="form-group input-group mb30">
              <input type="text" className="form-control form_control" name="telefono" value={telefono} onChange={(e) => handleChange(e)} placeholder="Telefono" />
            </div>
            <div className="form-group input-group mb30">
              <input type="email" className="form-control form_control" name="correo" value={correo} onChange={(e) => handleChange(e)} placeholder="Correo" />
            </div>
            <div className="form-group input-group mb30">
              <textarea name="mensaje" className="form-control" onChange={(e) => handleChange(e)}  rows="5">Hola, estoy interesado en </textarea>
            </div>
            <div className="ui_kit_checkbox">
              <div className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" id="customCheck1" />
                <label className="custom-control-label" htmlFor="customCheck1">Al enviar este formulario, aceptas los Términos de uso</label>
              </div>
            </div>
            <button onClick={(e) =>  buttonSubmit(e)} className="btn btn-block btn-thm mb10">CONTACTO</button>
            <a style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
            }} href={`https://api.whatsapp.com/send?phone=${phone}&text=Hola, estoy interesado en esta propiedad: ${url}`} className="btn btn-block btn-whatsapp mb0" target="_blank" rel="noreferrer">WHATSAPP</a>
          </form>
        </div>
      </div>
    </div>
  </div>
  );
};

export default AgentProfile;
