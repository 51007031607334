export const data = [
    {
        title: 'Inicio',
        url: '/',
        submenu: [

        ]
    },
    {
        title: 'Nosotros',
        url: '/nosotros',
    },
    {
        title: 'Proyectos',
        url: '/proyectos',
    },
    {
        title: 'Seminuevos',
        url: '/seminuevos',
    },
    {
        title: 'Contacto',
        url: '/contacto',
    },

]