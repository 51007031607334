import React, { useEffect, useState } from 'react'

export const Team = () => {
    const [teams, setTeams] = useState([])
    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getTeams = async () => {
            const res = await fetch("https://app.goodprop.cl/api/teams?populate=*");
            const json = await res.json();
            setTeams(json?.data);
        }

        getTeams();
    }, [])

    let teamsMap = teams && teams.map(team => team.attributes)
    console.log('teams', teamsMap);


    return (
        <section className="our-team pb40 bb1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>Conoce nuestro equipo</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div style={{ display: 'flex', gap: '20px' }} className="team_slider_home1">

                            {
                                teamsMap.map(team => (
                                    <div key={team.id} className="item">
                                        <div className="team_member">
                                            <div className="thumb"> <img className="img-fluid" src={imgBaseURL + team?.foto?.data?.attributes?.url} alt="1.jpg" />
                                                {/* <div className="overylay">
                                                <ul className="social_icon">
                                                    <li className="list-inline-item"><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                    <li className="list-inline-item"><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    <li className="list-inline-item"><a href="#"><i className="fa fa-instagram"></i></a></li>
                                                    <li className="list-inline-item"><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                                </ul>
                                            </div> */}
                                            </div>
                                            <div className="details">
                                                <h4><a href="page-agent-single.html">{team.name}</a></h4>
                                                <p>{team.cargo}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}



                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
