import React from 'react'

import {data} from './data'

import { useLocation, Link } from 'react-router-dom';

import LogoW from '../../assets/logo1_w.png'
import classNames from 'classnames';


export const DesktopHeader = () => {
    let location = useLocation();


    return (
<header 

className={classNames("header-nav menu_style_home_one home4_style navbar-scrolltofixed stricky main-menu", location.pathname === '/seminuevos' ? 'bgWhite' : location.pathname === '/proyectos' || location.pathname.indexOf('/propiedad')===0 && "bgWhite"  )} style={{
                zIndex: '1000',
                position: 'fixed',
                top: '0px',
                marginLeft: '0px',
                width: '100%',
                left: '0px',

            }}>
                <div className="container">
                    <nav>
                        <div className="menu-toggle">
                            <img className="nav_logo_img img-fluid" src="https://app.goodprop.cl/uploads/logo1_1_953589f4f8.png?updated_at=2022-03-19T00:24:03.713Z" alt="header-logo.svg" />
                            <button type="button" id="menu-btn">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                        </div>
                        <a style={{ width: '20%' }} href="/" className="navbar_brand float-left dn-md">
                            <img style={{ width: '70%   ' }} className={"logo1 img-fluid"} src={location.pathname === '/proyectos' || location.pathname === '/seminuevos' || location.pathname.indexOf('/propiedad')===0 || location.pathname.indexOf('/cotizacion')===0 ? "https://app.goodprop.cl/uploads/logo1_1_953589f4f8.png?updated_at=2022-03-19T00:24:03.713Z" : LogoW} alt="header-logo.svg" />
                            <img style={{ width: '70%   ' }} className="logo2 img-fluid" src="https://app.goodprop.cl/uploads/logo1_1_953589f4f8.png?updated_at=2022-03-19T00:24:03.713Z"  alt="header-logo2.svg" />
                        </a>
                        <ul id="respMenu" className="ace-responsive-menu float-left wa" data-menu-style="horizontal">
                            
                            {
                                data.map((item, index) => {
                                    return (
                                        item?.submenu?.length > 0 
                                        ? (
                                        <li key={index}> <Link to={item.url}><span  className="title">{item.title}</span></Link>
                                        <ul>
                                            {
                                                item.submenu.map((subitem, subindex) => {
                                                    return (
                                                        <li key={subindex}><Link   to={subitem.url}>{subitem.title}</Link></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                    )
                                    : 
                                    (
                                    <li key={index}> <Link to={item.url}><span className={classNames(
                                        location.pathname === '/proyectos' ||
                                        location.pathname === '/seminuevos' ||
                                        location.pathname.indexOf('/propiedad') === 0 ||
                                        location.pathname === '/seminuevos' === 0 ||
                                        location.pathname.indexOf('/cotizacion') === 0
                                        ? 'titleBlack' : 'title' )}>{item.title}</span></Link></li>)
                                    )
                                })
                            }
                            

                            
                        </ul>
                        <ul id="respMenu2" className="ace-responsive-menu float-right wa" data-menu-style="horizontal">
                            <li  className="list-inline-item list_c"><a href="tel:+56931904223" style={{color:  location.pathname === "/nosotros"  ? "white" : location.pathname === "/proyectos" || location.pathname === '/seminuevos' || location.pathname.indexOf('/propiedad')===0 ? "black" : null}}><span className="flaticon-phone mr7"></span> (+56) 9 31904223</a></li>
                            {/* <li  className="list-inline-item list_s"><a href="#" style={{color: location.pathname === "/nosotros" && "white"}} className="btn" data-toggle="modal" data-target="#logInModal"><span className="flaticon-user"></span></a></li> */}
                            {/* <li className="list-inline-item add_listing"><a href="page-dashboard-new-property.html"><span className="icon flaticon-button"></span><span className="dn-lg"> Admin</span></a></li> */}
                        </ul>
                    </nav>
                </div>
            </header>
    )
}
