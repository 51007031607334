import { Box, Heading, Hide, HStack, Input, RangeSlider, RangeSliderFilledTrack, RangeSliderThumb, RangeSliderTrack, Select, Show, Text, useNumberInput } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { getAllDepas } from '../api/buydepa'
import PropertyCard from '../components/Buydepa/PropertyCard'
//import PropertyCard from '../components/Buydepa/PropertyCard'
import { MdGraphicEq } from 'react-icons/md'
import Search from '../components/Buydepa/Search'
import { FaFilter } from 'react-icons/fa'
import useModal from '../hooks/useModal'
import FiltrosModal from '../components/FiltrosModal'
import useUF from '../hooks/useUF'

const SemiNuevos = () => {

    const { modals, setModal } = useModal();
    const { uf } = useUF();
  const [properties, setProperties] = React.useState([])
  const [filteredProperties, setFilteredProperties] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [min, setMin] = React.useState(0)
  const [max, setMax] = React.useState(0)
  const [minCuota, setMinCuota] = React.useState(0)
  const [maxCuota, setMaxCuota] = React.useState(0)
  const [minBedrooms, setMinBedrooms] = React.useState(0)
  const [maxBedrooms, setMaxBedrooms] = React.useState(0)
    const [minBathrooms, setMinBathrooms] = React.useState(0)
    const [maxBathrooms, setMaxBathrooms] = React.useState(0)
   const [minUF, setMinUF] = React.useState(0)
   const [maxUF, setMaxUF] = React.useState(0)
   const [minCuotaCLP, setMinCuotaCLP] = React.useState(0)
    const [maxCuotaCLP, setMaxCuotaCLP] = React.useState(0)
    const [bathRooms, setBathRooms] = React.useState(0)
    const [text, setText] = React.useState('')

    const [order, setOrder] = React.useState('')

  const getDepas = async () => {

    const response = await getAllDepas()
    setProperties(response.data)
    setLoading(false)

  }

  useEffect(() => {
    getDepas()
  }, [min, max])


  const getMinMaxUF = () => {
    //selling_information.final_selling_price

    const prices = properties.map((property) => {
        return property.attributes.selling_information.final_selling_price
        })

    const min = Math.min(...prices)
    const max = Math.max(...prices)

    setMinUF(Math.ceil(min))
    setMaxUF(Math.ceil(max))


  }

    const getMinMaxCuota = () => {
        
    const prices = properties.map((property) => {
        
        // return property.attributes.selling_information.rent_price
        const finalPrice  = property.attributes.selling_information.final_selling_price
        const pie = property.attributes.selling_information.downpayment_financing
        const pieDecimal = pie / 100
        const cuotasQuantity = property.attributes.selling_information.storage_selling_price || 60

        const cuota = (finalPrice * uf * pieDecimal) / cuotasQuantity
        return cuota

        })

    const min = Math.min(...prices)
    const max = Math.max(...prices)

    setMinCuotaCLP(Math.ceil(min))
    setMaxCuotaCLP(Math.ceil(max))
    }


  useEffect(() => {
    getMinMaxUF()
    getMinMaxCuota()
    getMinAndMaxBedrooms()
    getMinAndMaxBathrooms()

  }, [properties])
  



  const filterProperties = async () => {
    const response = await getAllDepas(min, max, bedRoomInput.value, bathRooms )
   // setFilteredProperties(response.data)

   //create a new array with the caprate
    const capRates = response?.data?.map((property) => {
        const finalPrice  = property.attributes.selling_information.final_selling_price
        const rentPrice = property.attributes.selling_information.rent_price
        const capRate = (100 * ((rentPrice * 12) / (finalPrice * uf))).toFixed(2)
        return capRate
    })

    // const cuotas = (finalPrice * uf * pieDecimal) / cuotasQuantity
    
    //add caprate to the object

    const cuotas = response?.data?.map((property) => {
        const finalPrice  = property.attributes.selling_information.final_selling_price
        const pie = property.attributes.selling_information.downpayment_financing
        const pieDecimal = pie / 100
        const cuotasQuantity = property.attributes.selling_information.storage_selling_price || 60

        const cuota = (finalPrice * uf * pieDecimal) / cuotasQuantity
        return cuota
    })

    const propertiesWithCapRate = response?.data?.map((property, index) => {
        return {...property, capRate: capRates[index]}
    })

    const propertiesWithCapRateAndCuota = propertiesWithCapRate?.map((property, index) => {
        return {...property, cuota: cuotas[index]}
    })



    if(order === ''){


        if(minCuota !== 0 && maxCuota !== 0){
            const orderCuotaRange = propertiesWithCapRateAndCuota?.filter((property) => {
                return property.cuota >= minCuota && property.cuota <= maxCuota
        
            })
            setFilteredProperties(orderCuotaRange)
           }else{
               setFilteredProperties(propertiesWithCapRateAndCuota)
           }

    }

    if (order === 'menorCaprate') {
        const sorted = propertiesWithCapRateAndCuota.sort((a, b) => a.capRate - b.capRate)

        if(minCuota !== 0 && maxCuota !== 0){
         const orderCuotaRange = sorted?.filter((property) => {
            return property.cuota >= minCuota && property.cuota <= maxCuota
            })
            setFilteredProperties(orderCuotaRange)
        }else{
            setFilteredProperties(sorted)
        }


    }

    if (order === 'mayorCaprate') {
        const sorted = propertiesWithCapRateAndCuota.sort((a, b) => b.capRate - a.capRate)
        if(minCuota !== 0 && maxCuota !== 0){
            const orderCuotaRange = sorted?.filter((property) => {
               return property.cuota >= minCuota && property.cuota <= maxCuota
               })
               setFilteredProperties(orderCuotaRange)
           }else{
               setFilteredProperties(sorted)
           }

    }

    if (order === 'menorCuota') {
        const sorted = propertiesWithCapRateAndCuota.sort((a, b) => a.cuota - b.cuota)
        if(minCuota !== 0 && maxCuota !== 0){
            const orderCuotaRange = sorted?.filter((property) => {
               return property.cuota >= minCuota && property.cuota <= maxCuota
               })
               setFilteredProperties(orderCuotaRange)
           }else{
               setFilteredProperties(sorted)
           }
    }

    if (order === 'mayorCuota') {
        const sorted = propertiesWithCapRateAndCuota.sort((a, b) => b.cuota - a.cuota)
        if(minCuota !== 0 && maxCuota !== 0){
            const orderCuotaRange = sorted?.filter((property) => {
               return property.cuota >= minCuota && property.cuota <= maxCuota
               })
               setFilteredProperties(orderCuotaRange)
           }else{
               setFilteredProperties(sorted)
           }
    }


        

  }

  //filter properties by orden in the select useEffect(() => {

  useEffect(() => {
    filterProperties()
  }, [order])


  



  const resetFilter = () => {
    setFilteredProperties([])
    setBathRooms(0)
  }

  
  const milles = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

const getMinAndMaxBedrooms = () => {
    const bedrooms = properties.map((property) => {
        return property.attributes.typology.bedrooms
        })

    const min = Math.min(...bedrooms)
    const max = Math.max(...bedrooms)

    setMinBedrooms(min)
    setMaxBedrooms(max)
}

const getMinAndMaxBathrooms = () => {
    const bathrooms = properties.map((property) => {
        return property.attributes.typology.bathrooms
        })

    const min = Math.min(...bathrooms)
    const max = Math.max(...bathrooms)

    setMinBathrooms(min)
    setMaxBathrooms(max)
}


const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
useNumberInput({
  step: 1,
  defaultValue: 0,
  min: minBedrooms,
  max: maxBedrooms,
})

const bedRoomInc = getIncrementButtonProps()
const bedRoomDec = getDecrementButtonProps()
const bedRoomInput = getInputProps()


const bathRoomInc = () => {    
    //increment the value limit 
    if (bathRooms < maxBathrooms) {
        setBathRooms(bathRooms + 1)
    }



}

const bathRoomDec = () => {

    if (bathRooms > minBathrooms) {
        setBathRooms(bathRooms - 1)
    }

}

const bathRoomInput = () => {
    return bathRooms
}





const onChangeHandler = (text) => {
 
    let matches = [];

    setText(text)
    console.log(text)

    if (text.length > 0) {
        matches = properties.filter(property => {
           console.log(property)
           const regex = new RegExp(`${text}`, "gi");
           return property.attributes.address.match(regex) || property.attributes.commune.match(regex)
        })

        setFilteredProperties(matches)
    } else {
        setFilteredProperties([])
        setText('')
    }

    
}

const onSubmmitHandler = (text) => {

    let matches = [];
    setText(text)


    if (text.length > 0) {
        matches = properties.filter(property => {
           console.log(property)
           const regex = new RegExp(`${text}`, "gi");
           return property.attributes.address.match(regex) || property.attributes.commune.match(regex)
        })

        setFilteredProperties(matches)
    } else {
        setFilteredProperties([])
        setText('')
    }


}




// const getMinCapRate = () => {
//     const capRates = properties.map((property) => {
//         const finalPrice  = property.attributes.selling_information.final_selling_price
//         const rentPrice = property.attributes.selling_information.rent_price
//         const capRate = (100 * ((rentPrice * 12) / (finalPrice * uf))).toFixed(2)
//         return capRate
//     })
//     const min = Math.min(...capRates)
//     return min
// }

// const getMaxCapRate = () => {
//     const capRates = properties.map((property) => {
//         const finalPrice  = property.attributes.selling_information.final_selling_price
//         const rentPrice = property.attributes.selling_information.rent_price
//         const capRate = (100 * ((rentPrice * 12) / (finalPrice * uf))).toFixed(2)
//         return capRate
//     })
//     const max = Math.max(...capRates)
//     return max
// }

// const filterPropertiesByMenorCapRate = () => {

//     console.log('filtering by caprate')
//     //filteredProperties
//     const properties = filteredProperties.sort((a, b) => {
//         return a.capRate - b.capRate
//     })
//     setFilteredProperties(properties)
//     }

    // const filterPropertiesByMayorCapRate = () => {
            
    //         console.log('filtering by caprate')
    //         //filteredProperties
    //         const properties = filteredProperties.sort((a, b) => {
    //             return b.capRate - a.capRate
    //         })
    //         setFilteredProperties(properties)
    //         }

        //     const handleOrder = () => {

    
        //         if(order === 'menorCaprate'){
        //             filterPropertiesByMenorCapRate()
        //         }
            
        // }


            const handleFilter = (e) => {
                e.preventDefault()
                filterProperties()
                setModal('filtros', false)
              }





  if (loading) {
    return              <div className='preloader'></div>

  }



  return (
    <Box as="section" className="home-two p0">
    <div className="container-fluid p0">
      <div className="row">
        <div className="col-lg-12">
          <div className="home_two_map">

            <div className='bg-homeSemiNuevos' />

            </div>
            
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                

            > 
                <Box 
                    w= '80%'
                className="tab-pane fade show active" id="rent" role="tabpanel" aria-labelledby="rent-tab">
                                        <div className="home_adv_srch_opt home4">
                                            <div className="wrapper">
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }} className="home_adv_srch_form home4">

                    <Search onChangeHandler={onChangeHandler}  text={text} onSubmmitHandler={onSubmmitHandler}  />
                    </div>
                    </div>
                    </div>
                    </Box>
                  

                    <Box>
                    <Show
    below='md'


    >
        {/* MOSTRAR FILTROS */}

        <Box
        w={{
            base: '100%',
            md: '80%',
            lg: '80%',
            xl: '80%',
        }}
        pr={'20px'}
        pl={'20px'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        onClick={() => setModal('filtros', true)}
            className='filter_open_btn btn-thm'
        >
      <Text
        fontSize='xl'
        color='white'
        fontWeight='bold'
        mr={'10px'}
      >
    Mostar filtros

      </Text>
      <FaFilter />


        
 </Box>





    </Show>
                    </Box>
                    
            </Box>

            <Box 
            pt="10"
              className='container'
            >
              <div className="row">

              <Box  className="col-xl-9">

<Box className="row">


<Box
        display={'flex'}
        px={8}
        py={4}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        w={'100%'}


      >

        <p className="mb0 mt10"><span className="heading-color">{filteredProperties.length > 0 ? (filteredProperties.length) : (properties.length)}</span> Propiedades</p>
        <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={'5px'}
        >
       
        <Select onChange={(e) => setOrder(e.target.value)}>
          <option selected disabled>Ordenar por</option>
        <option value="menorCaprate">Menor caprate </option>
    <option value="mayorCaprate">Mayor caprate </option>
    <option value="menorCuota">Menor cuota</option>
    <option value="mayorCuota">Mayor cuota</option>
          
        </Select>
      </Box>
      </Box>


{

filteredProperties.length > 0 ? filteredProperties.map((property) => (
    <>
    <PropertyCard key={property.id} {...property} />
    
    </>
)) :

  properties && properties.map((property) => (
    <>


        



    <PropertyCard key={property.id} {...property} />
    
    </>
  ))
}
</Box>
</Box>



    

{ modals.filtros && <FiltrosModal> 
    <div className="col-xl-3">
        <div style={{
              position: "sticky",
              top: "100px",
        }} className="sidebar_listing_grid1 mb30 dn-lg">
            <Box

                
              borderRadius =  "30px"
              backgroundColor = "#f4f4f4"
            className="sidebar_listing_list">
                <div className="sidebar_advanced_search_widget">
                    <ul className="sasw_list mb0">
                        <li><h4 className="mb0">Filtros</h4></li>


                        {/* <li>
                            <Heading as="h4"        fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}>
                                Ordenar
                            </Heading>
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDirection={'column'}
                            >
                                <Select 
                                    onChange={(e) => setOrder(e.target.value)}

    w="100%"
    >
    <option selected disabled value="option1">Ordenar por</option>
    <option value="menorCaprate">Menor caprate </option>
    <option value="mayorCaprate">Mayor caprate </option>
    <option value="menorCuota">Menor cuota</option>
    <option value="mayorCuota">Mayor cuota</option>
    
    </Select>
                    </Box>
                        </li> */}

                        <li className="search_area">
                            <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Rango UF</Heading>
                            <div className="form-group">
{/* INPUT UF MIN AND MAX */}

<Box 

display="flex"
justifyContent="space-between"
alignItems="center"
gap={2}
>
  <Input
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="UF Min"
  onChange={(e) => setMin(e.target.value)}
  value={min === 0 ? minUF + ' UF' : min + ' UF'}
  />
  <Input   
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="UF Max"
  onChange={(e) => setMax(e.target.value)}
  value={max === 0 ? maxUF + ' UF' : max + ' UF'}


  />
  </Box>

                            <RangeSlider aria-label={['min', 'max']} defaultValue={[
                              minUF || min,
                              maxUF || max,
                            ]}
                              min={minUF || 0}
                              max={maxUF || 1000}


                              
                              onChangeEnd={(value) => {
                                setMin(value[0])
                                setMax(value[1])
                              }}

                              //value


                            >
  <RangeSliderTrack bg='red.100'>
    <RangeSliderFilledTrack bg='tomato' />
  </RangeSliderTrack>
  <RangeSliderThumb boxSize={6} index={0}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
  <RangeSliderThumb boxSize={6} index={1}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
</RangeSlider>                            </div>
                        </li>

                        <li className="search_area">
                            <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Cuota CLP</Heading>
                            <div className="form-group">
{/* INPUT UF MIN AND MAX */}

<Box 

display="flex"
justifyContent="space-between"
alignItems="center"
gap={2}
>
  <Input
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="CLP Min"
  onChange={(e) => setMinCuota(e.target.value)}
  value={minCuota === 0 ? milles(minCuotaCLP) + ' CLP' : milles(minCuota) + ' CLP'}
  />
  <Input   
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="CLP Max"
  onChange={(e) => setMaxCuota(e.target.value)}
  value={maxCuota === 0 ? milles(maxCuotaCLP) + ' CLP' : milles(maxCuota) + ' CLP'}


  />
  </Box>

                            <RangeSlider aria-label={['min', 'max']} defaultValue={[
                              minCuotaCLP || minCuota,
                              maxCuotaCLP || maxCuota,
                            ]}
                              min={minCuotaCLP || 0}
                              max={maxCuotaCLP || 1000}


                              
                              onChangeEnd={(value) => {
                                setMinCuota(value[0])
                                setMaxCuota(value[1])
                              }}

                              //value


                            >
  <RangeSliderTrack bg='red.100'>
    <RangeSliderFilledTrack bg='tomato' />
  </RangeSliderTrack>
  <RangeSliderThumb boxSize={6} index={0}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
  <RangeSliderThumb boxSize={6} index={1}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
</RangeSlider>                            </div>
                        </li>
                        <li>
                        <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Tipología</Heading>
                            <Box className="form-group">
                                <Box 
         
                                >
                                <HStack maxW='320px'
                                                           
                                                           display={'flex'}
                                                           justifyContent={'space-between'}
                                                           alignItems={'center'}
                                >
                                    <Text> Dormitorios</Text>
                                    <Box>
                                <Box as="button"

                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    backgroundColor="#c8c8c8"
                                    fontSize={'1rem'}
                                    fontWeight="700"
                                    color="#FFF"

                                {...bedRoomDec}>-</Box>

      <Input
      isDisabled
        textAlign={'center'}
        w={10}
        border={0}
        focusBorderColor="none"
        color={'black'}
        fontWeight={700}
        fontSize={'1rem'}
        
      {...bedRoomInput} />
      <Box as="button"

w="40px"
h="40px"
borderRadius="50%"
backgroundColor="#c8c8c8"
fontSize={'1rem'}
fontWeight="700"
color="#FFF"
      {...bedRoomInc}>+</Box>
</Box>
    </HStack>
                                </Box>
                            </Box>
                        </li>
                        <li>
                            <Box className="form-group">
                                <Box 
         
                                >
                                <HStack maxW='320px'
                                                           
                                                           display={'flex'}
                                                           justifyContent={'space-between'}
                                                           alignItems={'center'}
                                >
                                    <Text> Baños</Text>
                                    <Box>
                                <Box as="button"

                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    backgroundColor="#c8c8c8"
                                    fontSize={'1rem'}
                                    fontWeight="700"
                                    color="#FFF"
                                    onClick={() => bathRoomDec()}
                                    >-</Box>

      <Input
      isDisabled
        textAlign={'center'}
        w={10}
        border={0}
        focusBorderColor="none"
        color={'black'}
        fontWeight={700}
        fontSize={'1rem'}
        value={bathRooms}
        {...bathRoomInput} />

       
      <Box as="button"

w="40px"
h="40px"
borderRadius="50%"
backgroundColor="#c8c8c8"
fontSize={'1rem'}
fontWeight="700"
color="#FFF"
 onClick={() => bathRoomInc()}   >+</Box>
</Box>
    </HStack>
                                </Box>
                            </Box>
                        </li>
                        <li>
                            <div className="search_option_button text-center mt25">
                                <button onClick={(e) => handleFilter(e)} className="btn btn-block btn-thm mb25">Buscar</button>
                                <ul className="mb0">
                                    <li className="list-inline-item mb0">
                                        <Heading 
                                            cursor={'pointer'}
                                            onClick={() => resetFilter()}
                                            fontSize={'1rem'}
                                            fontWeight={'400'}


                                        as="a" ><span className="vam flaticon-return mr10"></span> Limpiar búsqueda</Heading>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </Box>
            
        </div>
    </div>
    
    
     </FiltrosModal> }


   <Hide 
    below='md'

    
   >
   <div className="col-xl-3">
        <div style={{
              position: "sticky",
              top: "100px",
        }} className="sidebar_listing_grid1 mb30 dn-lg">
            <Box

                
              borderRadius =  "30px"
              backgroundColor = "#f4f4f4"
            className="sidebar_listing_list">
                <div className="sidebar_advanced_search_widget">
                    <ul className="sasw_list mb0">
                        <li><h4 className="mb0">Filtros</h4></li>


                        {/* <li>
                            <Heading as="h4"        fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}>
                                Ordenar
                            </Heading>
                            <Box
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                flexDirection={'column'}
                            >
                                <Select 
                                    onChange={(e) => setOrder(e.target.value)}

    w="100%"
    >
    <option selected disabled value="option1">Ordenar por</option>
    <option value="menorCaprate">Menor caprate </option>
    <option value="mayorCaprate">Mayor caprate </option>
    <option value="menorCuota">Menor cuota</option>
    <option value="mayorCuota">Mayor cuota</option>
    
    </Select>
                    </Box>
                        </li> */}

                        <li className="search_area">
                            <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Rango UF</Heading>
                            <div className="form-group">
{/* INPUT UF MIN AND MAX */}

<Box 

display="flex"
justifyContent="space-between"
alignItems="center"
gap={2}
>
  <Input
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="UF Min"
  onChange={(e) => setMin(e.target.value)}
  value={min === 0 ? minUF + ' UF' : min + ' UF'}
  />
  <Input   
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="UF Max"
  onChange={(e) => setMax(e.target.value)}
  value={max === 0 ? maxUF + ' UF' : max + ' UF'}


  />
  </Box>

                            <RangeSlider aria-label={['min', 'max']} defaultValue={[
                              minUF || min,
                              maxUF || max,
                            ]}
                              min={minUF || 0}
                              max={maxUF || 1000}


                              
                              onChangeEnd={(value) => {
                                setMin(value[0])
                                setMax(value[1])
                              }}

                              //value


                            >
  <RangeSliderTrack bg='red.100'>
    <RangeSliderFilledTrack bg='tomato' />
  </RangeSliderTrack>
  <RangeSliderThumb boxSize={6} index={0}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
  <RangeSliderThumb boxSize={6} index={1}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
</RangeSlider>                            </div>
                        </li>

                        <li className="search_area">
                            <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Cuota CLP</Heading>
                            <div className="form-group">
{/* INPUT UF MIN AND MAX */}

<Box 

display="flex"
justifyContent="space-between"
alignItems="center"
gap={2}
>
  <Input
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="CLP Min"
  onChange={(e) => setMinCuota(e.target.value)}
  value={minCuota === 0 ? milles(minCuotaCLP) + ' CLP' : milles(minCuota) + ' CLP'}
  />
  <Input   
  disabled
  borderRadius={'30px!important'}
  textAlign="center"
  placeholder="CLP Max"
  onChange={(e) => setMaxCuota(e.target.value)}
  value={maxCuota === 0 ? milles(maxCuotaCLP) + ' CLP' : milles(maxCuota) + ' CLP'}


  />
  </Box>

                            <RangeSlider aria-label={['min', 'max']} defaultValue={[
                              minCuotaCLP || minCuota,
                              maxCuotaCLP || maxCuota,
                            ]}
                              min={minCuotaCLP || 0}
                              max={maxCuotaCLP || 1000}


                              
                              onChangeEnd={(value) => {
                                setMinCuota(value[0])
                                setMaxCuota(value[1])
                              }}

                              //value


                            >
  <RangeSliderTrack bg='red.100'>
    <RangeSliderFilledTrack bg='tomato' />
  </RangeSliderTrack>
  <RangeSliderThumb boxSize={6} index={0}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
  <RangeSliderThumb boxSize={6} index={1}>
    <Box color='tomato' as={MdGraphicEq} />
  </RangeSliderThumb>
</RangeSlider>                            </div>
                        </li>
                        <li>
                        <Heading as="h4"
                            fontSize="1rem"
                            fontWeight="400"
                            color="#000"
                            pb={5}
                            >Tipología</Heading>
                            <Box className="form-group">
                                <Box 
         
                                >
                                <HStack maxW='320px'
                                                           
                                                           display={'flex'}
                                                           justifyContent={'space-between'}
                                                           alignItems={'center'}
                                >
                                    <Text> Dormitorios</Text>
                                    <Box>
                                <Box as="button"

                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    backgroundColor="#c8c8c8"
                                    fontSize={'1rem'}
                                    fontWeight="700"
                                    color="#FFF"

                                {...bedRoomDec}>-</Box>

      <Input
      isDisabled
        textAlign={'center'}
        w={10}
        border={0}
        focusBorderColor="none"
        color={'black'}
        fontWeight={700}
        fontSize={'1rem'}
        
      {...bedRoomInput} />
      <Box as="button"

w="40px"
h="40px"
borderRadius="50%"
backgroundColor="#c8c8c8"
fontSize={'1rem'}
fontWeight="700"
color="#FFF"
      {...bedRoomInc}>+</Box>
</Box>
    </HStack>
                                </Box>
                            </Box>
                        </li>
                        <li>
                            <Box className="form-group">
                                <Box 
         
                                >
                                <HStack maxW='320px'
                                                           
                                                           display={'flex'}
                                                           justifyContent={'space-between'}
                                                           alignItems={'center'}
                                >
                                    <Text> Baños</Text>
                                    <Box>
                                <Box as="button"

                                    w="40px"
                                    h="40px"
                                    borderRadius="50%"
                                    backgroundColor="#c8c8c8"
                                    fontSize={'1rem'}
                                    fontWeight="700"
                                    color="#FFF"
                                    onClick={() => bathRoomDec()}
                                    >-</Box>

      <Input
      isDisabled
        textAlign={'center'}
        w={10}
        border={0}
        focusBorderColor="none"
        color={'black'}
        fontWeight={700}
        fontSize={'1rem'}
        value={bathRooms}
        {...bathRoomInput} />

       
      <Box as="button"

w="40px"
h="40px"
borderRadius="50%"
backgroundColor="#c8c8c8"
fontSize={'1rem'}
fontWeight="700"
color="#FFF"
 onClick={() => bathRoomInc()}   >+</Box>
</Box>
    </HStack>
                                </Box>
                            </Box>
                        </li>
                        <li>
                            <div className="search_option_button text-center mt25">
                                <button onClick={(e) => handleFilter(e)} className="btn btn-block btn-thm mb25">Buscar</button>
                                <ul className="mb0">
                                    <li className="list-inline-item mb0">
                                        <Heading 
                                            cursor={'pointer'}
                                            onClick={() => resetFilter()}
                                            fontSize={'1rem'}
                                            fontWeight={'400'}


                                        as="a" ><span className="vam flaticon-return mr10"></span> Limpiar búsqueda</Heading>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </Box>
            
        </div>
    </div>
   </Hide>
</div>



            </Box>
            

            </div>
            </div>

{/* 
            <Box className='waves'>
                                    <Text as="p">¿Qué son los departamentos seminuevos?</Text>

                                    <Box>

                                    </Box>



            </Box> */}

            {/*     content: '';
    border-top-left-radius: 50% 100%;
    border-top-right-radius: 50% 100%;
    position: absolute;
    bottom: 0;
    z-index: -1;
    width: 100%;
    background-color: #0f0f10;
    height: 85%; */}



            </div>
            </Box>
  )
}

export default SemiNuevos