import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import mapMarker from '../../assets/mapmarker.png'
import Geocode from "react-geocode";
import PropTypes from 'prop-types';





export const LocationMap = ({ubicacion, comuna, latitude, longitude}) => {

    console.log(ubicacion, comuna)

    const APIMAP = "AIzaSyCOS6uuJqX9yDcR2B8wwuq-aknfQW1ptqg"
Geocode.setApiKey(APIMAP);
Geocode.setLanguage("es");
Geocode.setRegion("cl");

    const mapStyles = {
        height: "50vh",
        width: "100%",
        borderRadius: "10px"
    };
    const [lat, setLat] = useState()
    const [lng, setLng] = useState()
    Geocode.fromAddress(`${ubicacion}`).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          setLat(lat);
          setLng(lng);
          console.log(response, 'rep');
        },
        (error) => {
          console.error(error);
        }
      );

      console.log(lat, lng, 'desde el map')
      console.log(latitude, longitude, 'desde el map')


    const exampleMapStyles = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f6f8f9"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#f6f8f9"
                }
            ]
        },
        {
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#f6f8f9"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#716f6f"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#424242"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "labels.text",
            "stylers": [
                {
                    "color": "#ff0000"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "stylers": [
                {
                    "color": "#fcfcfc"
                },
                {
                    "saturation": -100
                },
                {
                    "weight": 0.5
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dedede"
                },
                {
                    "weight": 1.5
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]
    
    

      
    
      const defaultCenter = {
        lat: lat, lng: lng
    }



  return (

    <LoadScript
    googleMapsApiKey={APIMAP}>
    <GoogleMap
        options={{
            styles: exampleMapStyles,
        }}

        mapContainerStyle={mapStyles}
        zoom={13}
        center={defaultCenter}

    >
        <Marker icon={mapMarker}
            position={defaultCenter} />
    </GoogleMap>
</LoadScript>
  );
};

LocationMap.propTypes = {
    ubicacion: PropTypes.string,
    comuna: PropTypes.string,
};