import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const PropertyDetails = () => {
    const { slug } = useParams(); 

    const [propsType, setPropsType] = useState('Departamento');
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState([]);
    const [idDetails, setIdDetails] = useState([]);



    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}&populate=*`;
        const fetchTags = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json, 'details')
                setDetails(json?.data[0]?.attributes);
                setIdDetails(json?.data[0]?.id);
                setLoading(false);
            } catch (error) {
                console.log("error", error);
            }
        };
        fetchTags();
    }, []);

    const dataURl  = details?.property_type?.data?.id



        useEffect(() => {      

        
            fetchData();
        }, [dataURl]);
    
        const fetchData = async () => {
          try {
            if (dataURl !== undefined) {
              const response = await fetch(`https://app.goodprop.cl/api/property-types/${dataURl}?populate=*`);
              const json = await response.json();
              const jsonNombre = json?.data?.attributes?.nombre;
              setPropsType(jsonNombre);
              console.log(jsonNombre, '')
              setLoading(false);
            }
          } catch (error) {
              console.log("error", error);
          }
        
      };
    


  return (
    <div className="row">
    <div className="col-lg-12">
      <h4 className="mb15">Detalles de la propiedad</h4>
    </div>
    <div className="col-md-6 col-lg-6 col-xl-4">
      <ul className="list-inline-item">
        <li><p>ID:</p></li>
        <li><p>Precio:</p></li>
        <li><p>Tamaño:</p></li>
        <li><p>Fecha de Entrega:</p></li>
      </ul>
      <ul className="list-inline-item">
        <li><p><span>{idDetails}</span></p></li>
        <li><p><span>{`Desde UF ${details?.precio} Hasta UF ${details?.Precio2}`}</span></p></li>
        <li><p><span>{details?.mt2}mt²</span></p></li>
        <li><p><span>{details?.anno}</span></p></li>
      </ul>
    </div>
    <div className="col-md-6 col-lg-6 col-xl-4">
      <ul className="list-inline-item">
        <li><p>Habitaciones :</p></li>
        <li><p>Baños :</p></li>
        <li><p>Estacionamiento :</p></li>
        <li><p>Bodega :</p></li>
      </ul>
      <ul className="list-inline-item">
        <li><p><span>{details?.Habitaciones}</span></p></li>
        <li><p><span>{details?.Bannos}</span></p></li>
        <li><p><span>{details?.estacionamientos}</span></p></li>
        <li><p><span>{details?.bodega=== true ? 'Sí' : 'No'}</span></p></li>
      </ul>
    </div>
    <div className="col-md-6 col-lg-6 col-xl-4">
      <ul className="list-inline-item">
        <li><p>Tipo de Propiedad:</p></li>
        <li><p>Estado de la Propiedad:</p></li>
      </ul>
      <ul className="list-inline-item">
        <li><p><span>{loading ? 'Cargando...' : propsType}</span></p></li>
        <li><p><span>En Venta</span></p></li>
      </ul>
    </div>
  </div>
  );
};
