import React from 'react'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'

export const HomeParallaxForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data.name);

        const sendEmail = async () => {
            const res = await fetch('https://app.goodprop.cl/api/email/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    to: 'prospectos@goodprop.cl',
                    from: 'prospectos@goodprop.cl',
                    subject: 'Contacto desde la web',
                    html: ` Ha llegado una nueva solicitud de contacto desde GOODPROP.cl <br> <br> <b>Nombre:</b> ${data.name} <br> <b>Correo:</b> ${data.correo} <br> <b>Telefono:</b> ${data.telefono} <br> <b>Mensaje:</b> ${data.message}`
                })
            })
            const json = await res.json();
            console.log(json)
        }

        if (data.name && data.correo && data.telefono && data.message) {
            sendEmail()
            Swal.fire(
                'Mensaje enviado',
                'Gracias por contactarnos, pronto nos comunicaremos con usted.',
                'success'
            )
        } else {
            Swal.fire(
                'Error',
                'Por favor complete todos los campos',
                'error'
            )
        }



    };







    return (
        <section id="contact" className="divider home4 bg-img4 parallax" data-stellar-background-ratio="0.2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="property_sell_home4">
                            <h2 className="title text-white mb20">Agenda una asesoría gratuita con nosotros.</h2>
                            <p className="fz16 text-white fw500">La mejor forma que encuentres lo que buscas es hablando con uno de nuestros Goodpropers, quienes te ayudaran a elegir la mejor opción para lograr la libertad financiera.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-4 offset-xl-1">
                        <div className="divider_contact_form">
                            <div className="wrapper">
                                <form onSubmit={handleSubmit(onSubmit)}>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>NOMBRE</label>
                                                <input className="form-control form_control" {...register("name", { required: true })} placeholder="Juan Perez.." />
                                                <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.name && 'EL NOMBRE ES OBLIGATORIO'}</small></span>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>TELÉFONO</label>
                                                <input className="form-control form_control" {...register("telefono", { required: true })} placeholder="(+56) 9 578 975 99" />
                                                <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.telefono && 'EL TELEFONO ES OBLIGATORIO'}</small></span>

                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>CORREO</label>
                                                <input className="form-control form_control" type="email" {...register("correo", { required: true })} placeholder="prospectos@goodprop.cl" />
                                                <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.correo && 'EL CORREO ES OBLIGATORIO'}</small></span>

                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label>MENSAJE</label>
                                                <textarea className="form-control" rows="6" {...register("message", { required: true })} placeholder="Message"></textarea>
                                                <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.message && 'EL MENSAJE ES OBLIGATORIO'}</small></span>

                                            </div>
                                            <div className="form-group mb0">
                                                <button type="submit" className="btn btn-block form_btn">Enviar</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
