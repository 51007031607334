import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { LocationMap } from './LocationMap';

export const Location = () => {

    const { slug } = useParams(); 
    const [location, setLocation] = useState([]); 
    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;

        const fetchTags = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json)
                setLocation(json?.data[0]?.attributes);
            } catch (error) {
                console.log("error", error);
            }
        }

        fetchTags();

    }, [])







  return (
    <div className="location_details pb40 mt50 bb1">
    <div className="row">
      <div className="col-lg-12">
        <h4 className="mb15">Ubicación</h4>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-4">
        <ul className="list-inline-item mb0">
          <li><p>Dirección:</p></li>
          <li><p>Región: </p></li>
        </ul>
        <ul className="list-inline-item mb0">
          <li><p><span>{location?.ubicacion}</span></p></li>
          <li><p><span>{location?.region}</span></p></li>
        </ul>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-4">
        <ul className="list-inline-item mb0">
          <li><p>Comuna:</p></li>
          <li><p>País:</p></li>

        </ul>
        <ul className="list-inline-item mb0">
          <li><p><span>{location?.comuna}</span></p></li>
          <li><p><span>{location?.pais}</span></p></li>
        </ul>
      </div>
      <div className="col-md-6 col-lg-6 col-xl-4">
        <ul className="list-inline-item mb0">
        </ul>
        <ul className="list-inline-item mb0">
          <li><p><span>{location?.area}</span></p></li>
        </ul>
      </div>
      <div className="col-lg-12">
        <div className="property_sp_map mt30">
            
          <LocationMap ubicacion={location?.ubicacion} comuna={location?.comuna} latitude={location.latitude} longitude={location.longitude} />
        </div>
      </div>
    </div>
  </div>
  );
};
