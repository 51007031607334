import React, { useContext, useState } from 'react'
import { Box, Input, Select, Flex, Button, Link, Show, Hide,    } from '@chakra-ui/react'
import { IoFilterCircleOutline, IoFilterCircle } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { PropsContext } from '../../context/PropsContext';

const HomeSeach = ({text, onChangeHandler, uniqueCities}) => {
    let navigate = useNavigate();

    const [comunaField, setComunaField] = useState('');
    const [tipoEntregaField, setTipoEntregaField] = useState('');
    // const [habitacionesField, setHabitacionesField] = useState('');
    const [changeSearch, setChangeSearch] = useState(false);

    const { comuna, tipoEntrega, habitaciones, setComuna, setTipoEntrega } = useContext(PropsContext);



    const handleSubmit = (e) => {
        e.preventDefault();

        if(comunaField !== '') {
            setComuna(comunaField);
        }

        if(tipoEntregaField !== '') {
            setTipoEntrega(tipoEntregaField);
        }

        // if(habitacionesField !== '') {
        //     setHabitaciones(habitacionesField);
        // }
        navigate("/proyectos");


    }

    console.log({comuna, tipoEntrega, habitaciones})
    console.log({comunaField, tipoEntregaField})



  return (
    <>
        <Hide         breakpoint='(min-width: 724px)'>
            <Box
                w= '100%'
                bg={'#f5f5f5'}
                h='100%'
                p={4}
                borderRadius={10}
            >
                <Flex
                justifyContent={'space-evenly'}
                alignItems={'center'}
                width={'100%'}
                direction={'column'}
                
            >
                
                <Select 
    onChange={(e) => setComunaField(e.target.value)}
  >
    <option selected disabled> Selecciona una comuna</option>
    {
      uniqueCities && uniqueCities.map((city, index) => {
        return (
          <option key={index} value={city}>{city}</option>
        )
      })
    }
  </Select>
  <br />
          <Select
            onChange={(e) => setTipoEntregaField(e.target.value)}
          >
            <option selected disabled> Tipo de entrega</option>
  <option value='Inmediata'>Entrega Inmediata</option>
  <option value='Futura'>Entrega Futura</option>
</Select>
{/* <Select 
    onChange={(e) => setHabitacionesField(e.target.value)}
>
    <option selected disabled> Habitaciones</option>
  <option value='1'>1</option>
  <option value='2'>2</option>
  <option value='3'>3</option>
</Select> */}
<Box
    m={'0.5rem'}
    width={'100%'}
    display={'flex'}
    justifyContent={'center'}
>
<Button 
    width={'100%'}
    background='#FE5164'
    color={'white'}
    borderRadius={'5rem'}
    _hover={{background:'#f37d8b'}}
    onClick={handleSubmit}
    >
        
        <Link 
    href='/proyectos'
    >Buscar</Link></Button>
</Box>
</Flex>
            </Box>
</Hide>
    <Show         breakpoint='(min-width: 724px)'>
    <Box 
        w={'100%'}
        h={'auto'}
        bg={'#f5f5f5'}
        p={'1rem'}
        borderRadius={'5rem'}
        border={'1px solid #e6e6e6'}
        boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}
        >
            <Flex
                justifyContent={'space-evenly'}
                alignItems={'center'}
                width={'100%'}
                
            >
              
                                 <Box
                ml={'1rem'}
                mr={'1rem'}
                onClick={() => setChangeSearch(!changeSearch)}
            >
              {
                !changeSearch ? (
                  <IoFilterCircleOutline  color="#FE5164" size="30" />) : (
                  <IoFilterCircle color="#FE5164" size="30" />
                  )
                
              }
            </Box>
              
{
  !changeSearch ? (
    <>
          {/* <InputGroup>
    <InputLeftElement pointerEvents='none'>
    <IoIosSearch color='gray' size={20}  />
    </InputLeftElement>
          <Input 
            placeholder='Busca por comuna'
            size='md'
            onChange={(e) => setComunaField(e.target.value)}
            value={comunaField}
            />
  </InputGroup> */}
  <Select 
    onChange={(e) => setComunaField(e.target.value)}
  >
    <option selected disabled> Selecciona una comuna</option>
    {
     uniqueCities && uniqueCities.map((city, index) => {
        return (
          <option key={index} value={city}>{city}</option>
        )
      })
    }
  </Select>
          <Select
            onChange={(e) => setTipoEntregaField(e.target.value)}
          >
            <option selected disabled> Tipo de entrega</option>
  <option value='Inmediata'>Entrega Inmediata</option>
  <option value='Futura'>Entrega Futura</option>
</Select>
{/* <Select 
    onChange={(e) => setHabitacionesField(e.target.value)}
>
    <option selected disabled> Habitaciones</option>
  <option value='1'>1</option>
  <option value='2'>2</option>
  <option value='3'>3</option>
</Select> */}
<Box
    m={'0.5rem'}
>
<Button 
    background='#FE5164'
    color={'white'}
    borderRadius={'5rem'}
    width={120}
    _hover={{background:'#f37d8b'}}
    onClick={handleSubmit}
    >
        
        <Link 
    href='/proyectos'
    >Buscar</Link></Button>
</Box>
</>
  ) : (
    <Input 
      placeholder='Busca por comuna, zona, depto, etc'
      size='md' 
      onChange={(e) => onChangeHandler(e.target.value)}
      value={text}
      />
  )
}
</Flex>
    </Box>
    </Show>
    </>
  )
}

export default HomeSeach