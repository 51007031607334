import React, { useCallback, useContext, useEffect, useState } from 'react'
import EntregasDropdown from '../components/ProjectPage/EntregasDropdown'
import {Map} from '../components/ProjectPage/Map'

import axios from 'axios'
import { PropertyList } from '../components/ProjectPage/PropertyList'
import { useSearchParams } from 'react-router-dom';
import { PropsContext } from '../context/PropsContext'
import { Box, Heading, Input, Show } from '@chakra-ui/react'


//ENTREGA INMEDIATA, FUTURA






export const ProjectPage = () => {
  const imgBaseURL = 'https://app.goodprop.cl'
    const [status, setStatus] = useState(false)
    const [props, setProps] = useState([])
    const [text, setText] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [loading, setLoading] = useState(true)

    const [render, setRender] = useState(false)
    const [comunaField, setComunaField] = useState('')


    const { comuna, tipoEntrega, habitaciones, setComuna } = useContext(PropsContext);

  console.log({comuna, tipoEntrega, habitaciones})
  console.log({comunaField})


    const [searchParams] = useSearchParams();

    // const goToComuna = (comuna) =>
    // navigate({
    //   pathname: '/proyectos',
    //   search: '?comuna=' + comuna,
    // });

    const handleSubmit = () => {
      setRender(!render)
        if(comunaField !== '') {
            setComuna(comunaField);
        }




    }


    const getComuna = (comuna) => {

      if(comuna) {
        setComuna(comuna);
        handleSubmit()

    }
    }
    const tipoEntregaType = tipoEntrega ? `&filters[entrega][$eq]=${tipoEntrega}` : ''
    const comunaType = comuna ? `&filters[comuna][$contains]=${comuna}` : ''

    useEffect(() => {

        const loadUsers = async () => {



            const response = await axios.get(`https://app.goodprop.cl/api/properties?populate=*&pagination[start]=0&pagination[limit]=-1${tipoEntregaType}${comunaType}`);
            setProps(response.data.data)
            setLoading(false)
        }

        loadUsers()

    }, [render])

    const listProps = props.map(props => {
      return {
          'id': props.id,
          'name': props?.attributes?.nombre,
          'price': props?.attributes?.precio,
          'price2' : props?.attributes?.Precio2,
          'city': props?.attributes?.ciudad?.data?.attributes?.nombre,
          'img': imgBaseURL + props?.attributes?.featured_image?.data?.attributes?.url,
          'bathrooms': props?.attributes?.Bannos,
          'bedrooms': props?.attributes?.Habitaciones,
          'garage': props?.attributes?.estacionamientos,
          'area': props?.attributes?.mt2,
          'lat': props?.attributes?.latitude,
          'lng': props?.attributes?.longitude,
          'telefono': props?.attributes?.broker?.data?.attributes?.telefono,
          'entrega' : props?.attributes?.entrega,
          'tags': props?.attributes?.tags,
          'slug': props?.attributes?.slug, 
      }
  })

  const listCities = props.map(props => {
    return {
        'id': props.id,
        'name': props?.attributes?.ciudad?.data?.attributes?.nombre,
    }
})

const uniqueCities = [...new Set(listCities.map(item => item.name))]


//get city and properties count
const cityProps = uniqueCities.map(city => {
  const cityProps = listCities.filter(item => item.name === city)
  const cityPropsCount = cityProps.length
  return {
      'name': city,
      'count': cityPropsCount
  }
})


//filter properties bv entrega inmediata, futura
const entregaFutura = listProps.filter(item => item.entrega === 'Futura')
const entregaInmediata = listProps.filter(item => item.entrega === 'Inmediata')

 

  const getEntregaInmediata = useCallback(() => {
    if (searchParams.get('entrega') === 'inmediata'){
      setSuggestions(entregaInmediata)

    } else if (searchParams.get('entrega') === 'futura'){
      setSuggestions(entregaFutura)
    }
   }, [])
   


  useEffect(() => {
    getEntregaInmediata()
  }, [getEntregaInmediata])


// const onChangeHandler = (text) => {
//   let matches = [];

//   if(entrega === 'Inmediata'){
//      matches = entregaInmediata.filter(props => {
//       const regex = new RegExp(`${text}`, 'gi');
//       return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) || props.price2.match(regex) || props.entrega.match(regex)
//     })

//     setSuggestions(matches)
//     setText(text)
  
//   }else if (entrega === 'Futura' && text.length > 0){
//     console.log('futura')
//     matches = entregaFutura.filter(props => {
//       const regex = new RegExp(`${text}`, 'gi');
//       return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) || props.price2.match(regex) || props.entrega.match(regex)
//     })
//     setSuggestions(matches)
//     setText(text)
//   }else  if (text.length > 0) {
//       console.log(text, 'text')
//     // no deja escribir en el input
//       matches = listProps.filter(props => {
//           const regex = new RegExp(`${text}`, 'gi');
//           return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) || props.price2.match(regex) ||  props.entrega.match(regex)
//       })
//       console.log(matches, 'text matches')
//       setSuggestions(matches)
//       setText(text)

//   } else {
//       setSuggestions([])
//       setText('')
//   }
// }
    

//filter by comuna and update suggestions
const onChangeComuna = (comuna) => {
  let matches = [];
  if(comuna === 'all'){
    matches = listProps.filter(props => {
      const regex = new RegExp(`${text}`, 'gi');
      return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) || props.price2.match(regex) || props.entrega.match(regex)
    })
    setSuggestions(matches)
    setText(text)
  }else{
    matches = listProps.filter(props => {
      const regex = new RegExp(`${text}`, 'gi');
      return props.name.match(regex) || props.city.match(regex) || props.price.match(regex) || props.price2.match(regex) || props.entrega.match(regex) && props.city === comuna
    })
    setSuggestions(matches)
    setText(text)
  }
}



// const filterByComuna = () => {
//   console.log(searchParams.get('comuna'), 'comuna')
//   if (searchParams.get('comuna')){
//     const matches = listProps.filter(props => {
//       return props.city === searchParams.get('comuna')
//     })
//     setSuggestions(matches)
//   }
// }

useEffect (() => {

  

  onChangeComuna('santiago')

}, [searchParams.get('comuna')])




//function change space to - and lowercase
// const slugify = (text) => {
//   return text.toString().toLowerCase()
//   .replace(/\s+/g, '-')           // Replace spaces with -
//   .replace(/^-+/, '')             // Trim - from start of text
//   .replace(/-+$/, '');            // Trim - from end of text
// }









    return (
      <>
      {
        !loading && (<div>



  <section className="home-two p0">
    <div className="container-fluid p0">
      <div className="row">
        <div className="col-lg-12">
          <div className="home_two_map">
            <Map suggestions={suggestions} />
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
        <Show         breakpoint='(min-width: 770px)'>

          <div className="home_adv_srch_opt listing_page_v7 text-center">
            <div className="wrapper">
              <div className="home9-advnc-search">
                <ul className="h1ads_1st_list mb0">
                  <li className="list-inline-item">
                    <div className="home_form_input home9">
                      <div className="input-group">
                        <div className="home2_befor_icon"><span className="flaticon-houses"></span></div>
                        <div className="select-wrap style1-dropdown">
                        <input
                                                                            style={{ width: '100%', border: 'none', height: '52px' }}
                                                                            type="search"
                                                                            className="form-control"
                                                                            placeholder="Buscar una propiedad..."
                                                                            onChange={e => setComunaField(e.target.value)}
                                                                            value={comunaField}
                                                                        />
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div style={{ width: '100%'}} className="custom_dropdown_widget">
                      <div style={{ width: '100%'}} className="btn drop_btn" onClick={() => setStatus(!status)}><span className="flaticon-car-key"></span>{tipoEntrega? 'Entrega ' +tipoEntrega : 'Tipo de Entrega'}</div>

                        {
                            status && (
                                <EntregasDropdown status={status} setStatus={setStatus} />
                            )
                        }

                        
                    </div>
                  </li>
               
                  <li className="list-inline-item float-right fn-lg">
                    <div className="search_option_button home9">
                      <button type="submit" onClick={handleSubmit} className="btn btn-thm"><span className="flaticon-magnifiying-glass"></span> Buscar</button>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          </Show>

          <Show breakpoint='(max-width: 770px)'>

<Box
  display="flex"
  flexDirection="column"
  justifyContent="center"
  alignItems="center"
  bgcolor="#fff"
  boxShadow={'0 10px 50px rgb(13 38 59 / 10%)'}
  mt={10}
  p={5}
  style={{ height: 'auto' }}
                        

>
  <Heading fontSize={20}>
    Filtra las propiedades
    </Heading>
    <br />
            <Input 
            size={'lg'}
            style={{ width: '100%', border: 'none', height: '52px', backgroundColor: '#eee' }}
            type="search"
            className="form-control"
            placeholder="Buscar una propiedad..."
            onChange={e => setComunaField(e.target.value)}
            value={comunaField}

            />     
    <br />
    <li style={{width: '100%',}}className="list-inline-item">
                    <div style={{width: '100%',}} className="custom_dropdown_widget">
                      <div style={{width: '100%',}} className="btn drop_btn" onClick={() => setStatus(!status)}><span className="flaticon-car-key"></span>{tipoEntrega? 'Entrega ' +tipoEntrega : 'Tipo de Entrega'}</div>

                        {
                            status && (
                                <EntregasDropdown status={status} setStatus={setStatus} />
                            )
                        }

                        
                    </div>
                  </li>
    <br />
                  <li style={{width: '100%',}} className="list-inline-item float-right fn-lg">
                    <div style={{width: '100%',}} className="search_option_button home9">
                      <button style={{width: '100%',}} onClick={handleSubmit} className="btn btn-thm"><span className="flaticon-magnifiying-glass"></span> Buscar</button>
                    </div>
                  </li>

</Box>



          </Show>


        </div>
      </div>
    </div>
  </section>

  <section className="our-listing pb30-991">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="listing_sidebar dn db-lg">
            <div className="sidebar_content_details style3">
              <a href="javascript:void(0)" className="closebtn" onClick="closeNav()">&times;</a> 
              <div className="sidebar_listing_list style2 mobile_sytle_sidebar mb0">
                <div className="siderbar_widget_header">
                  <h4 className="title mb0">Find Your New Home<a className="filter_closed_btn float-right" href="#"><small>x</small><span className="flaticon-close"></span></a></h4>
                </div>
                <div className="sidebar_advanced_search_widget">
                  <ul className="sasw_list mb0">
                    <li className="search_area">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Enter Keyword" />
                      </div>
                    </li>
                    <li>
                      <div className="search_option_two">
                        <div className="sidebar_select_options">
                          <select className="selectpicker w100 show-tick">
                            <option value="">Status</option>
                            <option value="Active">Active</option>
                            <option value="Pending">Pending</option>
                            <option value="Approved">Approved</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="search_option_two">
                        <div className="sidebar_select_options">
                          <select className="selectpicker w100 show-tick">
                            <option>Type</option>
                            <option value="Apartment">Apartment</option>
                            <option value="Condo">Condo</option>
                            <option value="Studio">Studio</option>
                            <option value="Villa">Villa</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="search_option_two">
                        <div className="sidebar_select_options">
                          <select className="selectpicker w100 show-tick">
                            <option>All Cities</option>
                            <option value="London">London</option>
                            <option value="NewYork">New York</option>
                            <option value="Paris">Paris</option>
                            <option value="Istanbul">Istanbul</option>
                            <option value="Amsterdam">Amsterdam</option>
                            <option value="Rome">Rome</option>
                            <option value="LogAngeles">Log Angeles</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="search_option_two">
                        <div className="sidebar_select_options">
                          <select className="selectpicker w100 show-tick">
                            <option>Bedrooms</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </li>
                    <li className="search_area">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Min. Area"/>
                      </div>
                    </li>
                    <li className="search_area">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Max. Area"/>
                      </div>
                    </li>
                    <li className="search_area">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Property ID" />
                      </div>
                    </li>
                    <li>
                      <div className="sidebar_priceing_slider_mobile">
                        <div className="wrapper">
                          <p className="mb0">Price Range</p>
                          <div className="mt20" id="slider"></div>
                          <span id="slider-range-value1"></span>
                          <span id="slider-range-value2"></span>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="sidebar_accordion_widget mt40">
                        <div id="accordion" className="panel-group">
                          <div className="panel">
                            <div className="panel-heading">
                              <h4 className="panel-title other_fet">
                                <a href="#panelBodyRating2" className="accordion-toggle link text-thm" data-toggle="collapse" data-parent="#accordion"><i className="icon fa fa-plus"></i> Other Features</a>
                              </h4>
                            </div>
                            <div id="panelBodyRating2" className="panel-collapse collapse">
                              <div className="panel-body">
                                <ul className="ui_kit_checkbox selectable-list">
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck10"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck10">Air Conditioning</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck11"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck11">Barbeque</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck12"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck12">Dryer</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck13"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck13">Gym</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck14"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck14">Laundry</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck15"/>
                                      <label className="custom-control-label mb0" htmlFor="customCheck15">Lawn</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck16" />
                                      <label className="custom-control-label mb0" htmlFor="customCheck16">Microwave</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck17" />
                                      <label className="custom-control-label mb0" htmlFor="customCheck17">Outdoor Shower</label>
                                    </div>
                                  </li>
                                  <li className="mb0">
                                    <div className="custom-control custom-checkbox">
                                      <input type="checkbox" className="custom-control-input" id="customCheck18" />
                                      <label className="custom-control-label mb0" htmlFor="customCheck18">Refrigerator</label>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="search_option_button text-center mt25">
                        <button type="submit" className="btn btn-block btn-thm mb25">Search</button>
                        <ul className="mb0">
                          <li className="list-inline-item mb0"><a href="#"><span className="vam flaticon-return mr10"></span> Reset Search</a></li>
                          <li className="list-inline-item mb0 ml30"><a href="#"><span className="vam flaticon-heart-shape-outline mr10"></span> Saved Search</a></li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row">
        <div className="col-lg-12">
          <div className="dn db-lg mb30-md text-center">
            <div id="main2"><a id="open2" className="filter_open_btn btn-thm" href="#">Show Filter <span className="flaticon-setting-lines ml10"></span></a></div>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xl-3">
          <div className="sidebar_listing_grid1 mb30 dn-lg">
            {/* <div className="terms_condition_widget">
              <h4 className="title mb10">Categories</h4>
              <div className="widget_list">
                <ul className="list_details">
                  <li><a href="#">Apartment <span className="float-right">5</span></a></li>
                  <li><a href="#">Condo <span className="float-right">8</span></a></li>
                  <li><a href="#">Family House <span className="float-right">12</span></a></li>
                  <li><a href="#">Modern Villa <span className="float-right">5</span></a></li>
                  <li><a href="#">Town House <span className="float-right">2</span></a></li>
                </ul>
              </div>
            </div> */}
                    <Show         breakpoint='(min-width: 770px)'>

            <div className="terms_condition_widget">
              <h4 className="title mb10">Comunas</h4>
              <div className="widget_list">
                <ul className="list_details">
                  {
                    cityProps.map((item, index) => (
                      <li onClick={() => getComuna(item.name)} key={index}>
                        <a style={{
                          cursor: 'pointer',
                        }} 
                        
                        >{item.name} <span className="float-right">{item.count}</span></a>
                      </li>
                    ))
                  }

                </ul>
              </div>
            </div>
            </Show>
            {/* <div className="terms_condition_widget">
              <h4 className="title mb10">Featured Properties</h4>
              <div className="sidebar_feature_property_slider">
                <div className="item">
                  <div className="feat_property sidebar mb10">
                    <div className="thumb">
                      <img className="img-whp" src="images/property/fp1.jpg" alt="fp1.jpg" />
                      <div className="thmb_cntnt">
                        <ul className="tag mb0">
                          <li className="list-inline-item"><a href="#">For Rent</a></li>
                          <li className="list-inline-item"><a href="#">Featured</a></li>
                        </ul>
                        <h4 className="title posr color-white">New Apartment Nice Wiew</h4>
                        <a className="fp_price" href="#">$7,500/mo <small><del>$2,800/mo</del></small></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feat_property sidebar mb10">
                    <div className="thumb">
                      <img className="img-whp" src="images/property/fp1.jpg" alt="fp1.jpg" />
                      <div className="thmb_cntnt">
                        <ul className="tag mb0">
                          <li className="list-inline-item"><a href="#">For Rent</a></li>
                          <li className="list-inline-item"><a href="#">Featured</a></li>
                        </ul>
                        <h4 className="title posr color-white">New Apartment Nice Wiew</h4>
                        <a className="fp_price" href="#">$7,500/mo <small><del>$2,800/mo</del></small></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feat_property sidebar mb10">
                    <div className="thumb">
                      <img className="img-whp" src="images/property/fp1.jpg" alt="fp1.jpg" />
                      <div className="thmb_cntnt">
                        <ul className="tag mb0">
                          <li className="list-inline-item"><a href="#">For Rent</a></li>
                          <li className="list-inline-item"><a href="#">Featured</a></li>
                        </ul>
                        <h4 className="title posr color-white">New Apartment Nice Wiew</h4>
                        <a className="fp_price" href="#">$7,500/mo <small><del>$2,800/mo</del></small></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feat_property sidebar mb10">
                    <div className="thumb">
                      <img className="img-whp" src="images/property/fp1.jpg" alt="fp1.jpg" />
                      <div className="thmb_cntnt">
                        <ul className="tag mb0">
                          <li className="list-inline-item"><a href="#">For Rent</a></li>
                          <li className="list-inline-item"><a href="#">Featured</a></li>
                        </ul>
                        <h4 className="title posr color-white">New Apartment Nice Wiew</h4>
                        <a className="fp_price" href="#">$7,500/mo <small><del>$2,800/mo</del></small></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="feat_property sidebar mb10">
                    <div className="thumb">
                      <img className="img-whp" src="images/property/fp1.jpg" alt="fp1.jpg" />
                      <div className="thmb_cntnt">
                        <ul className="tag mb0">
                          <li className="list-inline-item"><a href="#">For Rent</a></li>
                          <li className="list-inline-item"><a href="#">Featured</a></li>
                        </ul>
                        <h4 className="title posr color-white">New Apartment Nice Wiew</h4>
                        <a className="fp_price" href="#">$7,500/mo <small><del>$2,800/mo</del></small></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="col-xl-9">
          <div className="row">
            <div className="listing_filter_row dif db-767">
              <div className="col-sm-12 col-md-4 col-lg-4 col-xl-5">
                <div className="left_area tac-xsd mb30-767">
                  <p className="mb0 mt10"><span className="heading-color">{suggestions.length === 0 ? (listProps.length) : (suggestions.length)}</span> {suggestions.length || listProps.length > 1 ? "Propiedades" : "Propiedad"} </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8 col-xl-7">
                <div className="listing_list_style tac-767">
                  {/* <ul className="mb0">
                    <li className="list-inline-item dropdown text-left bb2"><span className="stts">Short by:</span>
                      <select className="selectpicker">
                        <option>Newest Listings</option>
                        <option>Most Recent</option>
                        <option>Recent</option>
                        <option>Best Selling</option>
                        <option>Old Review</option>
                      </select>
                    </li>
                    <li className="list-inline-item gird"><a href="#"><span className="fa fa-th-large"></span></a></li>
                    <li className="list-inline-item list"><a href="#"><span className="fa fa-th-list"></span></a></li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>
          <PropertyList suggestions={suggestions} listProps={listProps}  />
        </div>
      </div>
    </div>
  </section>
        </div>
        )}
        </>
)
}
