import { useContext } from 'react';
import { ModalsContext } from '../context/Modals';

function useModal() {
    const { modals, setModals } = useContext(ModalsContext);

    const setModal = (modalKey, isVisible) =>
        setModals({ ...modals, [modalKey]: isVisible });

    const closeOthersAndKeep = (modalKey, isVisible = true) => {
        const stateCloned = { ...modals };
        Object.keys(stateCloned).forEach((modal) => {
            stateCloned[modal] = false;
        });
        setModals({ ...stateCloned, [modalKey]: isVisible });
    };

    return {
        modals,
        setModals,
        setModal,
        closeOthersAndKeep,
    };
}

export default useModal;
