import {  Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, {  useState } from 'react'

const AditionalInformation = (depa) => {

    console.log(depa.attributes)
    //get tab index
    
    const [tabIndex, setTabIndex] = useState(0)
    const tabRef = React.useRef(null) 
    const tabRef2 = React.useRef(null)
    const tabRef3 = React.useRef(null)

    const handleTabsChange = (index) => {

    setTabIndex(index)
    }
 

    




  return (
    <section className='container pt0'>
    <div className="px-4 py-5 text-left sm:px-6"><h2 className="text-lg font-medium leading-6 text-gray-900">Información Adicional</h2></div>
    <div className="px-3">
        <div className="bg-white sm:rounded-lg">
            <div>


                    <Tabs  variant="solid-rounded" colorScheme="red" index={tabIndex} onChange={handleTabsChange} >
                        
                        
                     
  <TabList
    flexDirection={{
        base: "column",
        md: "row",

    }}
  >
        
    <Tab >Propiedad</Tab>
    <Tab >Características</Tab>
    <Tab >Comunidad</Tab>

  </TabList>

{/* DROPDOWN OF TABS */}

  {/* <Select onChange={(e) => handleTabsChange(e.target.value)} value={tabIndex} className="w-1/2">
    <option value="0">Propiedad</option>
    <option value="1">Características</option>
    <option value="2">Comunidad</option>
    </Select> */}

  <TabPanels>
    <TabPanel ref={tabRef}>
    <table className="min-w-full divide-y divide-gray-300">
                <tbody className="text-left bg-white">
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Inmobiliaria</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes?.developer}</td>
                    </tr>
                    <tr className="bg-gray-50 text-indigo-700">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Número de Departamento</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes?.internal_address?.unit}</td>
                    </tr>
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Año de Construcción</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.antiquity}</td>
                    </tr>
                    <tr className="bg-gray-50 text-indigo-700">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Tipo</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.type}</td>
                    </tr>
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Ámbito</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.ambit}</td>
                    </tr>
                    <tr className="bg-gray-50 text-indigo-700">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cantidad de Ascensores</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.building_elevators}</td>
                    </tr>
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Orientación</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.orientation}</td>
                    </tr>
                    <tr className="bg-gray-50 text-indigo-700">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cantidad de Pisos</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.building_floors}</td>
                    </tr>
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cantidad de Departamentos</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.building_apartments}</td>
                    </tr>
                    <tr className="bg-gray-50 text-indigo-700">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Número de Piso</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.internal_address?.floor}</td>
                    </tr>
                    <tr className="">
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Amoblado</td>
                        <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.furnitured ? 'Sí' : 'No'}</td>
                    </tr>
                </tbody>
            </table>
    </TabPanel>
    <TabPanel ref={tabRef2}>
    <table className="min-w-full divide-y divide-gray-300">
    <tbody className="text-left bg-white">
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Alarma</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.alarm ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Escritorio</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.job_desk ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Loggia</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.loggia ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Terraza con malla</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.terrace_net ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Portón Automático</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.auto_gate ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cocina Americana</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.american_kitchen ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Hall</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.hall ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Pieza de Planchado</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.ironing_room ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cocina Amoblada</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.furnitured_kitchen ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Comedor de diario</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.dining_room ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Hidromasaje</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.hydro_massage ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Reja en ventanas</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.window_grill ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Cocina equipada</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.equiped_kitchen ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Despensa</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.pantry ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Jacuzzi</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.jacuzzi ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Sala de Estar</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.living_room ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Persianas</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.blinds ? 'Sí' : 'No'}</td>
        </tr>
    </tbody>
</table>

    </TabPanel>
    <TabPanel ref={tabRef3}>
    <table className="min-w-full divide-y divide-gray-300">
    <tbody className="text-left bg-white">
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Accesos Controlados</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.controlled_access ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Estacionamientos de Visita</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.visit_garage ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Multicancha</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.multicourt ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Porton Electrico</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.electric_gate ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Sala de Juegos</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.game_room ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Juegos Infantiles</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.child_games ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Áreas Verdes</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.green_area ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Gimnasio</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.gym ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Pajareras</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.aviary ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Quinchos</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.barbecue ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Sauna</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.sauna ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Piscina</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.pool ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Circuito Cerrado de TV</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.tv_closed_circuit ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Jacuzzi</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.jacuzzi ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Panel Solar</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.solar_panel ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Sala de Cine</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.movie_theater ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Citófono</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.doorphone ? 'Sí' : 'No'}</td>
        </tr>
        <tr className="bg-gray-50 text-indigo-700">
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6 w-2/5">Sala de Eventos</td>
            <td className="py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6">{depa.attributes.amenities.event_room ? 'Sí' : 'No'}</td>
        </tr>
    </tbody>
</table>

    </TabPanel>
  </TabPanels>
</Tabs>
            </div>
            
        </div>
    </div>
</section>

  )
}

export default AditionalInformation