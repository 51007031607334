import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import useUF from '../../../../hooks/useUF'
import { milles } from '../../../../utils/toMilles'

const PromotionalCuotas = (depa) => {

    const { uf } = useUF()

    const finalPrice = Math.ceil(depa.attributes.selling_information.final_selling_price)
    const pie = depa.attributes.selling_information.downpayment_financing
    const pieDecimal = pie / 100
    const cuotasQuantity = depa.attributes.selling_information.storage_selling_price || 60


    const cuotas = () => {

        return (finalPrice * uf * pieDecimal) / cuotasQuantity
    }

//     <div className="p-2 rounded-lg shadow-lg bg-buydepa-blue">
//     <div className="flex flex-wrap items-center justify-between">
//         <div className="flex items-center flex-1 w-0">
//             <span className="flex p-2 rounded-lg">
//                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="w-6 h-6 text-white">
//                     <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
//                     ></path>
//                 </svg>
//             </span>
//             <p className="ml-3 font-medium text-white truncate"><span>PAGA EN 60 CUOTAS</span></p>
//         </div>
//         <div className="flex-shrink-0 order-3 w-full pr-4 mt-2 text-white sm:order-2 sm:mt-0 sm:w-auto">
//             <p className="ml-3 text-xs xsm:hidden"><span>PAGA EN 60 CUOTAS</span></p>
//             <span><span>$ 225.592</span></span>
//         </div>
//     </div>
// </div> 
//text-xl font-medium leading-6 text-buydepa-blue

  return (
    <section className="container pt0 pb0" >
        <Heading 
            as="h2"
            fontWeight={500}
            size="md" 
            color="#fe5164"
            mb={4}
            >Te financiamos hasta el 15% de pie</Heading>

    <Box 
        padding= "0.5rem"
        borderRadius="lg"
        boxShadow={2}
        bg="#fe5164"
    >
        <Box
            display="flex"
            flexWrap="wrap"
            alignItems="center"
            justifyContent="space-between"
        >
            <Box
                display="flex"
                alignItems="center"
                flex="1 0 0"
            >
                <Box as="span" p={2} borderRadius="lg">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" aria-hidden="true" className="w-6 h-6 text-white" style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    color: 'white'
                }}>
                     <path
                         strokeLinecap="round"
                         strokeLinejoin="round"
                         d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                     ></path>
                 </svg>
                    </Box>

                    <Text 
                        ml={3}
                        fontWeight="medium"
                        color="white"
                        isTruncated
                    >
                        <Text as="span" >
                            PAGA EN 60 CUOTAS
                        </Text>
                    </Text>
                    </Box>

                    <Box
                        pr={'1rem'}
                    >
                        {/* <Hide  above='sm' >
                        <p className="ml-3 text-xs xsm:hidden"><span>PAGA EN 60 CUOTAS</span></p>
                        </Hide> */}
                        <Text as="span"
                            color="white"
                        >
                            $ {milles(cuotas())}
                        </Text>
                        </Box>
                        </Box>
                        

    </Box>
    </section>
  )
}

export default PromotionalCuotas