import React, { useEffect, useState }  from 'react'
import { useParams } from 'react-router';
import FsLightbox from 'fslightbox-react';

export const ImagesProperty = () => {
   const baseImageUrl = 'https://app.goodprop.cl';
   const { slug } = useParams(); 


    const [image, setImage] = useState('')
    const [images, setImages] = useState({})
    const [tags, setTags] = useState([])
    const [loading, setLoading] = useState(true)
    const [lightboxController, setLightboxController] = useState({
      toggler: false,
      slide: 1
      });

      function openLightboxOnSlide(number) {
        setLightboxController({
        toggler: !lightboxController.toggler,
        slide: number
        });
        }


   useEffect(() => {
    const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}&populate=featured_image`;

    const fetchData = async () => {
        try {
            const response = await fetch(url);
            const json = await response.json();
            setImage(json?.data[0]?.attributes?.featured_image?.data?.attributes?.url);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    };

    fetchData();
}, []);

useEffect(() => {
  const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;

  const fetchTags = async () => {
      try {
          const response = await fetch(url);
          const json = await response.json();
          setTags(json?.data[0]?.attributes?.tags);
          setLoading(false);
      } catch (error) {
          console.log("error", error);
      }
  };

  fetchTags();
}, []);


useEffect(() => {
    const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}&populate=fotos`;

    const fetchDataAll = async () => {
        try {
            const response = await fetch(url);
            const json = await response.json();
            setImages(json?.data[0]?.attributes?.fotos);
            setLoading(false);
        } catch (error) {
            console.log("error", error);
        }
    };

    fetchDataAll();
}, []);

const firstImage = baseImageUrl+image
const getAllImg = [images].map(image => image?.data?.map(im => baseImageUrl+im?.attributes?.url));
const fusion = getAllImg.join(',').split(',').concat(firstImage);

console.log(fusion, "fusion");
console.log(fusion.length, "fusion.length");






  const tagsArr = tags?.toString().split(",");


    let imagesMap = 
         images && [images.data].map((image) => {
            if (Array.isArray(image)) {
                return image.map((image, index) => {
                    return (
                        <div key={index} className="col-sm-4 col-lg-4 pr5 pr15-767">
                        <div className="spls_style_two mb10">
                          <a className="popup-img" onClick={() => openLightboxOnSlide(index+1)}>
                              <img className="img-fluid w100"  src={baseImageUrl+image?.attributes?.url} alt={image} /></a>
                        </div>
                      </div>
                    );
                });
            }

         })

         
         if(loading){
            return (
                <div className="loading">
                    <img src="https://app.goodprop.cl/images/loading.gif" alt="loading" />
                </div>
            );
         }



    return (
        <>
<div className="row">
        <div className="col-lg-7">
          <div className="row">
            <div className="col-lg-12 pr0 pl15-767 pr15-767">
                
                  
                        <div className="spls_style_two mb30-md">
                        <a  onClick={() => openLightboxOnSlide(fusion.length)}>
                          <img className="img-fluid first-img" src={baseImageUrl+image} alt="1.jpg" />
                        </a>
                        {tagsArr && tagsArr[0] && <a href="#"><span className="baddge_left">{tagsArr && tagsArr[0]}</span></a>}
                        {tagsArr && tagsArr[1] && <a href="#"><span className="baddge_right">{tagsArr && tagsArr[1]}</span></a>}
                      </div>
                    
                


            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="row">

                {imagesMap}

                            {/* <div className="overlay popup-img">
                  <h3 className="title">+4</h3>
                </div> */}


          </div>
        </div>
      </div>

      <FsLightbox
toggler={lightboxController.toggler}
sources={fusion}
slide={lightboxController.slide}

/>
      </>
    )
}
