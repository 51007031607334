import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';

export const Breadcrums = () => {
  const { slug } = useParams();
  const [breadcrums, setBreadcrums] = useState([]);
  useEffect(() => {
    
    const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;

    const fetchTags = async () => {
        try {
            const response = await fetch(url);
            const json = await response.json();
            console.log(json)
            setBreadcrums(json?.data[0]?.attributes);
        } catch (error) {
            console.log("error", error);
        }
    }
  
    fetchTags();
    
  }, [])
  
  

    return (
        <div className="breadcrumb_content style3">
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><a href="#">Inicio</a></li>
          <li className="breadcrumb-item"><a href="#">Departamentos</a></li>
          <li className="breadcrumb-item active style2" aria-current="page">{breadcrums.nombre}</li>
        </ol>
      </div>
      )
    
}
