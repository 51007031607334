import React, { useState } from 'react';
import { ModalsContext } from './context';

const ModalsProvider = ({ children }) => {
    const [modals, setModals] = useState({
        cotizador: false,
        filtros: false,
        buydepaImages: false,


    });

    return (
        <ModalsContext.Provider value={{ modals, setModals }}>
            {children}
        </ModalsContext.Provider>
    );
};

export default ModalsProvider;
