import React from 'react'

export const ModalAuth = () => {
    return (
        <div className="sign_up_modal modal fade" id="logInModal" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body container pb30 pl0 pr0 pt0">
                            <div className="row">
                                <div className="col-lg-12">
                                    <ul className="sign_up_tab nav nav-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Sign in</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Register</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-content container" id="myTabContent">
                                <div className="row mt30 tab-pane fade show active pl20 pr20" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="col-lg-12">
                                        <div className="login_form">
                                            <form action="#">
                                                <div className="mb-2 mr-sm-2">
                                                    <label htmlFor="formGroupExampleInput" className="form-label mb0">Login</label>
                                                    <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Username.." />
                                                </div>
                                                <div className="form-group mb5">
                                                    <input type="password" className="form-control" placeholder="Password" />
                                                </div>
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox" className="custom-control-input" id="exampleCheck2" />
                                                    <label className="custom-control-label" htmlFor="exampleCheck2">Remember me</label>
                                                    <a className="btn-fpswd float-right text-thm" href="#">Forgot</a>
                                                </div>
                                                <button type="submit" className="btn btn-log btn-block btn-thm mt20">Sign in</button>
                                                <div className="row mt10">
                                                    <div className="col-lg-12">
                                                        <div className="user_log_info">
                                                            <p>Username: <span>agency or agent</span></p>
                                                            <p>Password: <span>demo</span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt30 tab-pane fade pl20 pr20" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div className="col-lg-12">
                                        <div className="sign_up_form">
                                            <form action="#">
                                                <div className="form-group input-group">
                                                    <input type="text" className="form-control" placeholder="Login" />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="text" className="form-control" placeholder="First name" />
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="text" className="form-control" placeholder="Last name" />
                                                </div>
                                                <div className="form-group input-group">
                                                    <select className="selectpicker form-control" data-width="100%">
                                                        <option data-tokens="banking">User role</option>
                                                        <option data-tokens="digital&creative">User role 2</option>
                                                        <option data-tokens="retail">User role 3</option>
                                                        <option data-tokens="humanresource">User role 4</option>
                                                    </select>
                                                </div>
                                                <div className="form-group input-group">
                                                    <input type="email" className="form-control" placeholder="Email" />
                                                </div>
                                                <div className="form-group input-group mb20">
                                                    <input type="password" className="form-control" placeholder="Password" />
                                                </div>
                                                <div className="form-group input-group mb30">
                                                    <input type="password" className="form-control" placeholder="Re-enter password" />
                                                </div>
                                                <button type="submit" className="btn btn-signup btn-block btn-dark mb0">REGISTER</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
