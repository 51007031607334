import React, { useEffect, useState } from 'react'

export const AboutText = () => {
    const [about, setAbout] = useState([])
    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getTeams = async () => {
            const res = await fetch("https://app.goodprop.cl/api/about?populate=*");
            const json = await res.json();
            setAbout(json?.data);
        }

        getTeams();
    }, [])

    return (
        <section className="about-section bb1 pb70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>{about?.attributes?.principal}</h2>
                            <p>{about?.attributes?.subtitulo}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="about_thumb mb30-smd">
                            <img className="img-fluid w100" src={imgBaseURL + about?.attributes?.imagen?.data?.attributes?.url} alt="2.jpg" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about_content">
                            <p>
                                {about?.attributes?.contenido}
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
