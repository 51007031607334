import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

export const Downloads = () => {
    const { slug } = useParams(); 
    const [file, setFiles] = useState('')
    const baseURL = 'https://app.goodprop.cl';

    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}&populate=archivos`;
    
        const fetchData = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                setFiles(json?.data[0]?.attributes?.archivos);
            } catch (error) {
                console.log("error", error);
            }
        };
    
        fetchData();
    }, []);

    console.log(file, 'file')



    let filesMap = 
    file && [file.data].map((files) => {
       if (Array.isArray(files)) {
           return files.map((filess, index) => {
                return (
                    <li key={index} className="list-inline-item">
                        <span className="icon flaticon-file"></span>&nbsp;&nbsp;{filess?.attributes?.caption} 
                        <a className="tdu text-thm" href={baseURL+filess?.attributes?.url} >Descargar</a></li>

                )
           });
       }

    })

    


  return (
    <div className="property_attachment_area pb40 bb1">
    <h4 className="mb15">Documentos</h4>
    <ul className="document_list mb0">
        {filesMap}
    </ul>
  </div>
  );
};
