import React, { useState } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router';
import { getDepaBySlug } from '../../../api/buydepa';
import AditionalInformation from './components/AditionalInformation';
import BasicInformation from './components/BasicInformation';
import Header from './components/Header';
import PromotionalCuotas from './components/PromotionalCuotas'; 
import Map from './components/Map';

const PropertyDetails = () => {
  const { slug } = useParams();

  const [depa, setDepa] = useState({});
  const [loading, setLoading] = useState(true);


  const getDepa = async () => {
    const response = await getDepaBySlug(slug)
    setDepa(response.data[0])
    setLoading(false)
  }

  useEffect(() => {
    getDepa()
  }, [])


if(loading) return <div className='preloader'></div>


  return (
    <>
    <Header  {...depa}/>
    <PromotionalCuotas {...depa} />
    <BasicInformation {...depa} />
    <AditionalInformation {...depa} />
    <Map {...depa} />
    </>
  )
}

export default PropertyDetails