import React, { forwardRef } from 'react';
import cls from 'classnames';
import styles from './button.module.css';

const Button = forwardRef(
  (
    {
      children,
      variant,
      color,
      startIcon,
      endIcon,
      rounded,
      className,
      size,
      fullWidth,
      ...rest
    },
    ref
  ) => (
    <button
      {...rest}
      ref={ref}
      className={cls(
        styles.button,
        {
          [styles[variant]]: variant,
          [styles[`${variant}-${color}`]]: color && variant,
          [styles.rounded]: rounded,
          [styles[size]]: size,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
    >
      {startIcon && (
        <span className={cls(styles.iconBox, styles.startIcon)}>
          {startIcon}
        </span>
      )}
      <span className={styles.text}>{children}</span>
      {endIcon && (
        <span className={cls(styles.iconBox, styles.endIcon)}>{endIcon}</span>
      )}
    </button>
  )
);

Button.displayName = 'Button';

Button.defaultProps = {
  variant: 'filled',
};

export default Button;
