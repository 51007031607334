import React from 'react'
import { ContactMap } from '../ContactMap'
import { useForm } from "react-hook-form";
import Swal from 'sweetalert2'

export const Contact = () => {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

    const onSubmit = (data) => {
        console.log(data.email, 'email');

        const sendEmail = async () => {
            const res = await fetch('https://app.goodprop.cl/api/email/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    to: data.email,
                    from: 'prospectos@goodprop.cl',
                    subject: 'Contacto desde la web',
                    html: ` Ha llegado una nueva solicitud de contacto desde GOODPROP.cl <br> <br> <b>Nombre:</b> ${data.name} <br> <b>Correo:</b> ${data.email} <br> <b>Mensaje:</b> ${data.message}`
                })
            })
            const json = await res.json();
            console.log(json)
        }

        if (data.name && data.email && data.message) {
            sendEmail()
            Swal.fire(
                'Mensaje enviado',
                'Gracias por contactarnos, pronto nos comunicaremos con usted.',
                'success'
            )
        } else {
            Swal.fire(
                'Error',
                'Por favor complete todos los campos',
                'error'
            )
        }

    };
    return (
        <section className="our-contact">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>¡Siempre estamos ansiosos por saber de ti!</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-6 col-lg-4 col-xl-3 pr0 pl0 plpr15-lg">
                        <div className="contact_icon_box mb50">
                            <div className="icon float-left"><span className="flaticon-map"></span></div>
                            <div className="details">
                                <h5 className="title">Dirección</h5>
                                <p>Augusto legia norte 262, oficina D<br />Las Condes, Santiago, Chile.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3">
                        <div className="contact_icon_box mb50">
                            <div className="icon float-left"><span className="flaticon-phone"></span></div>
                            <div className="details">
                                <h5 className="title">Contacto</h5>
                                <p>Teléfono: +56 9 31904223<br />Correo: prospectos@goodprop.cl</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-4 col-xl-3 pr0 pl0 plpr15-lg">
                        <div className="contact_icon_box mb50">
                            <div className="icon float-left"><span className="flaticon-clock"></span></div>
                            <div className="details">
                                <h5 className="title">Horario laboral</h5>
                                <p>Lunes a Viernes 09:00 - 18:00<br /></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <ContactMap />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="form_grid mt100">
                            <h2 className="text-center mb50">Envíanos un correo</h2>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="form_name" className="form-control" {...register("name", { required: true })} placeholder="Nombre" />
                                            <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.name && 'EL NOMBRE ES OBLIGATORIO'}</small></span>

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input name="form_email" className="form-control required email" {...register("email", { required: true })} type="email" placeholder="Email" />
                                            <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.email && 'EL EMAIL ES OBLIGATORIO'}</small></span>

                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <textarea name="form_message" className="form-control required" rows="6" {...register("message", { required: true })} placeholder="Escribe tu mensaje"></textarea>
                                            <span><small style={{ color: 'red', fontWeight: 'bold' }}>{errors.message && 'EL MENSAJE ES OBLIGATORIO'}</small></span>

                                        </div>
                                        <div className="form-group mb0">
                                            <button type="submit" className="btn btn-thm">ENVIAR</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
