import React from 'react';
import { Routes, Route } from "react-router-dom";
import './styles/global.css'
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header';

import { HomePage } from './pages/HomePage';
import { AboutPage } from './pages/AboutPage';
import { ContactPage } from './pages/ContactPage';
import { ProjectPage } from './pages/ProjectPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { PropertyPage } from './pages/PropertyPage';

import { ChakraProvider } from '@chakra-ui/react'
import { BedProvider, EntregaProvider } from './context/EntregaContext';
import { PropsProvider } from './context/PropsContext';
import SemiNuevos from './pages/SemiNuevos';
import ModalsProvider from './context/Modals/provider';
import PropertyDetails from './components/Buydepa/PropertyDetails';

import { Buffer } from 'buffer'
import CotizacionPage from './pages/CotizacionPage';
import CotizacionDetails from './pages/CotizacionDetails';

globalThis.Buffer = Buffer


function App() {
  return (
    <PropsProvider>
      <EntregaProvider>
        <BedProvider>
          <ChakraProvider>
            <ModalsProvider>
              <div className="wrapper">
                <div className='preloader'></div>
                <Header />
                <Routes>
                  <Route path='*' element={<NotFoundPage />} />

                  <Route path="/" element={<HomePage />} />
                  <Route path="/nosotros" element={<AboutPage />} />
                  <Route path="/contacto" element={<ContactPage />} />
                  <Route path="/proyectos" element={<ProjectPage />} />
                  <Route path="/proyecto/:slug" element={<PropertyPage />} />
                  <Route path="/seminuevos" element={<SemiNuevos />} />
                  <Route path="/seminuevos/:slug" element={<PropertyDetails />} />
                  <Route path="/cotizacion/:slug/:uuid" element={<CotizacionPage />} />
                  <Route path="/cotizacion/user/:email" element={<CotizacionDetails />} />

                </Routes>
                <Footer />

              </div>
            </ModalsProvider>
          </ChakraProvider>
        </BedProvider>
      </EntregaProvider>
    </PropsProvider>


  );
}

export default App;
