import React from 'react'
import FAB from '../components/FAB/FAB'
import { AgentSingleGridView } from '../components/PropertyPage/AgentSingleGridView'
import { ListingSingleProperty } from '../components/PropertyPage/ListingSingleProperty'

export const PropertyPage = () => {
    return (
        <>
            <ListingSingleProperty />
            <AgentSingleGridView />
            <FAB anchor="#contact" text="Asesoría gratuita" isPerson />

        </>
    )
}
