import React from 'react'
import useModal from '../../../../hooks/useModal'
import useUF from '../../../../hooks/useUF'
import { milles } from '../../../../utils/toMilles'
import CotizarModalDetails from '../../../CotizarModalDetails'

const BasicInformation = (depa, id) => {

    console.log(depa, 'depas')
    console.log(id, 'ud')

    const { uf } = useUF()
    const { modals, setModal } = useModal();

    const { bedrooms, bathrooms } = depa.attributes.typology
    const {area_int, area_ext, area_total} = depa.attributes.area
    const {final_selling_price} = depa.attributes.selling_information
    const { description } = depa.attributes.amenities

    const price = final_selling_price * uf

  return (
    <>
    <section className='pt0 pb0'>
    <div className="grid grid-cols-1 gap-6 mx-auto mt-8 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section className="text-left pt0 pb0">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 className="text-lg font-medium leading-6 text-gray-900">Información Básica</h2>
                        <p className="max-w-2xl mt-1 text-sm text-gray-500">Información básica de la propiedad</p>
                    </div>
                    <div className="border-t border-gray-200 px-8 py-3 sm:px-8">
                        <h3 className="font-medium text-gray-900">Programa</h3>
                        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Dormitorios</dt>
                                <dd className="text-gray-900">{ bedrooms }</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Baños</dt>
                                <dd className="text-gray-900">{bathrooms}</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="border-gray-200 px-8 py-3 sm:px-8">
                        <h3 className="font-medium text-gray-900">Superficie m²</h3>
                        <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Interior</dt>
                                <dd className="text-gray-900">{area_int}</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Terraza</dt>
                                <dd className="text-gray-900">{area_ext}</dd>
                            </div>
                            <div className="flex justify-between py-3 text-sm font-medium">
                                <dt className="text-gray-500">Total</dt>
                                <dd className="text-gray-900">{area_total}</dd>
                            </div>
                        </dl>
                    </div>
                    <div className="px-8 py-3 border-gray-200 sm:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th colSpan="4" className="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-6 md:pl-0">Detalle</th>
                                    <th scope="col" className="py-3.5 text-right text-sm text-gray-900">Cantidad</th>
                                    <th scope="col" className="py-3.5 text-right text-sm text-gray-900">Precio UF</th>
                                    <th scope="col" className="py-3.5 text-right text-sm text-gray-900">Precio Pesos</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                <tr>
                                    <td colSpan="4" className="py-3 pl-4 text-sm font-medium text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0">Departamento</td>
                                    <td className="py-3 pl-4 text-sm font-medium text-right text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0">1</td>
                                    <td className="py-3 pl-4 text-sm font-medium text-right text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0"><span>{Math.ceil(final_selling_price)} UF</span></td>
                                    <td className="py-3 pl-4 text-sm font-medium text-right text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0"><span>$ {milles(price)}</span></td>
                                </tr>
                                <tr>
                                    <td colSpan="5" className="py-3 pl-4 text-sm font-medium text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0">TOTAL</td>
                                    <td className="py-3 pl-4 text-sm font-medium text-right text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0"><span>{Math.ceil(final_selling_price)} UF</span></td>
                                    <td className="py-3 pl-4 text-sm font-medium text-right text-gray-500 whitespace-nowrap sm:pl-6 md:pl-0"><span>$ {milles(price)}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1 pt0 pb0">
            <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900">Informacion Adicional</h2>
                <div className="flow-root mt-6"><p className="text-sm text-gray-500">Buscamos las mejores oportunidades de inversión en departamentos para ti. Te entregaremos toda la información para que puedas invertir de manera fácil. Te asesoramos 100% online en tu proceso de compra.</p></div>
                <div className="flex flex-col mt-6 justify-stretch">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-gp-red focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={() => setModal('cotizador', true)}
                    >
                        Cotizar
                    </button>
                </div>
            </div>
        </section>
    </div>
</section>


<section className='container pt0 pb0'>
    <div className="text-left my-4">
        <div className="bg-white sm:rounded-lg">
            <div className="px-4 pt-5 pb-4 sm:px-6"><h2 className="text-lg leading-6 font-medium text-gray-900">Descripción</h2></div>
            <div className="border-gray-200 px-8 sm:px-6"><p className="mt-1 text-justify text-md text-gray-500">{description}</p></div>
        </div>
    </div>
</section>


{ modals.cotizador && <CotizarModalDetails depa={depa} /> }

    </>
  )
}

export default BasicInformation