import React from 'react'
import { Link } from 'react-router-dom';

import { data } from './data'
import LogoW from '../../assets/logo1_w.png'

export const MobileHeader = () => {
    return (
<div id="page" className="stylehome1 h0">
                <div className="mobile-menu">
                    <div className="header stylehome1">
                        <div className="main_logo_home2 text-center flex-logo"> <img className="nav_logo_img img-fluid mt10 responseImg " src={LogoW} alt="header-logo.svg" />  </div>
                        <ul className="menu_bar_home2">
                            <li className="list-inline-item"><a className="custom_search_with_menu_trigger msearch_icon" href="#"></a></li>
                            <li className="list-inline-item"><a className="muser_icon" href="#" data-toggle="modal" data-target="#logInModal"></a></li>
                            <li className="list-inline-item"><a className="menubar" href="#menu"><span></span></a></li>
                        </ul>
                    </div>
                </div>
                <nav id="menu" className="stylehome1">
                    <ul>
                        {   
                            data.map((item, index) => {
                                return (
                                    item?.submenu?.length > 0
                                    ? (<li key={index}><span>{item.title}</span>
                                        <ul>
                                            {
                                                item.submenu.map((subitem, subindex) => {
                                                    return (
                                                    <li key={subindex}><Link className='mm-btn mm-btn_next mm-listitem__btn mm-listitem__text' to={subitem.url}>{subitem.title}</Link></li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>
                                    )
                                    :
                                    (<li key={index}><Link className='mm-listitem__text' to={item.url}>{item.title}</Link></li>)
                                )
                            })
                        }
                    </ul>
                </nav>
            </div>

    )
}
