import React from 'react'

export const Footer = () => {
    return (
<section className="footer_one home1 home4">
                <div className="container pb90">
                    <div className="row">
                        <div className="col-sm-5 col-md-5 col-lg-3 col-xl-2">
                            <div className="footer_contact_widget home4">
                                <h4>Contacto</h4>
                                <ul className="list-unstyled">
                                    <li className="df">
                                        <span className="flaticon-map mr15"></span>
                                        <a >Augusto legia norte 262, oficina D, Las Condes</a>
                                    </li>
                                    <li>
                                        <span className="flaticon-phone mr15"></span>
                                        <a href="tel:+56931904223">+56 9 31904223</a>
                                    </li>
                                    <li>
                                        <span className="flaticon-mail-inbox-app mr15"></span>
                                        <a href="mailto:prospectos@goodprop.cl">prospectos@goodprop.cl</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer_social_widget home4 mt20">
                                <ul className="mb0">
                                    <li className="list-inline-item">
                                        <a href="https://www.facebook.com/GOOD-PROP-102600032131647">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/goodprop/">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/company/good-prop">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-sm-5 col-md-2 col-lg-2 col-xl-2">
                            <div className="footer_qlink_widget home4 pl0">
                                <h4>Links</h4>
                                <ul className="list-unstyled">
                                    <li>
                                        <a href="/nosotros">Nosotros</a>
                                    </li>
                                    <li>
                                        <a href="/proyectos">Proyectos</a>
                                    </li>
                                    <li>
                                        <a href="/contacto">Contacto</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-7 col-md-6 col-lg-3 col-xl-4">
                            <div className="footer_social_widget home4">
                                <h4>Subscribe</h4>
                                <p className="mb30">Nosotros no enviaremos spam, no te preocupes.</p>
                                <form className="footer_mailchimp_form home4">
                                    <div className="form-row align-items-center">
                                        <div className="col-auto">
                                            <input type="email" className="form-control" id="inlineFormInput" placeholder="Enter your email" />
                                            <button type="submit" className="flaticon-email"></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="container pt10 pb30">
                    <div className="row">
                        <div className="col-md-4 col-lg-4">
                            <div className="copyright-widget home4 mt10 mb15-767">
                                <p>Copyright © 2022 GoodProp. All Right Reserved.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="footer_logo_widget home4 text-center mb15-767">
                                <div className="wrapper">
                                    <div className="logo text-center">
                                        <img style={{ width: '50%'}} src="https://goodprop.cl/old/wp-content/uploads/2021/09/logo1.png" alt="footer-logo2.svg" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4">
                            <div className="footer_menu_widget home4 text-right tac-md mt15">
                                <ul>
                                    <li className="list-inline-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">Site Map</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">Privacy policy</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="#">Cookie Policy</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
