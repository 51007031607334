import React, { useEffect, useState } from 'react'

import { useParams } from 'react-router';

export const PropsHeader = () => {
  console.log(useParams());
    const { slug } = useParams();
    const [dataHeader, setDataHeader] = useState([]);

    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;

        const fetchTags = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json)
                setDataHeader(json?.data[0]?.attributes);
            } catch (error) {
                console.log("error", error);
            }
        }

        fetchTags();

    }, [])







    return (
        <div className="media">
        <div className="media-body">
          <h2 style={{ fontSize: 20, fontWeight: 'bold'}} className="mt-0">{dataHeader?.nombre}</h2>
          <p>{dataHeader?.direccion}</p>
          <ul className="prop_details mb0">
            <li className="list-inline-item mr20"><a className="mr20" ><span className="flaticon-bed pr5 vam"></span>{dataHeader?.Habitaciones}  Habitaciones</a></li>
            <li className="list-inline-item mr20"><a className="mr20" ><span className="flaticon-bath pr5 vam"></span> {dataHeader?.Bannos} Baños</a></li>
            <li className="list-inline-item mr20"><a className="mr20" ><span className="flaticon-car pr5 vam"></span> {dataHeader?.estacionamientos} Estacionamiento</a></li>
            <li className="list-inline-item mr20"><a className="mr20" ><span className="flaticon-ruler pr5 vam"></span> {dataHeader?.mt2} mt²</a></li>
            <li className="list-inline-item mr20"><a ><span className="flaticon-calendar pr5 vam"></span> Fecha de Entrega: {dataHeader?.anno}</a></li>
          </ul>
        </div>
      </div>
    )
}
