import React, { } from 'react'
import { DesktopHeader } from './DesktopHeader'
import { MobileHeader } from './MobileHeader'
import { ModalAuth } from './ModalAuth'



export const Header = () => {


    return (
        <>
            <DesktopHeader />
            <ModalAuth />
            <MobileHeader />
        </>
    )
}

