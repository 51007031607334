import React, { useState } from 'react'
import { Box, Input,  Flex, Button, Link, Show,     } from '@chakra-ui/react'
import { BiSearchAlt } from 'react-icons/bi'

const Search = ({onSubmmitHandler}) => {

    // const [habitacionesField, setHabitacionesField] = useState('');
    const [changeSearch, setChangeSearch] = useState(true);
    const [search, setSearch] = useState('');

    

    const handleSubmit = (e) => {
        e.preventDefault();

    }




  return (
    <>
        
    <Show         breakpoint='(min-width: 724px)'>
    <Box 

        w={'80%'}
        h={'auto'}
        bg={'#f5f5f5'}
        p={'1rem'}
        borderRadius={'5rem'}
        border={'1px solid #e6e6e6'}
        boxShadow={'0px 0px 10px rgba(0, 0, 0, 0.1)'}
        >
            <Flex
                justifyContent={'space-evenly'}
                alignItems={'center'}
                width={'100%'}
                
            >
              
                                 <Box
                ml={'1rem'}
                mr={'1rem'}
                onClick={() => setChangeSearch(!changeSearch)}
            >

            </Box>
              
{
  !changeSearch ? (
    <>
          {/* <InputGroup>
    <InputLeftElement pointerEvents='none'>
    <IoIosSearch color='gray' size={20}  />
    </InputLeftElement>
          <Input 
            placeholder='Busca por comuna'
            size='md'
            onChange={(e) => setComunaField(e.target.value)}
            value={comunaField}
            />
  </InputGroup> */}

{/* <Select 
    onChange={(e) => setHabitacionesField(e.target.value)}
>
    <option selected disabled> Habitaciones</option>
  <option value='1'>1</option>
  <option value='2'>2</option>
  <option value='3'>3</option>
</Select> */}
<Box
    m={'0.5rem'}
>
<Button 
    background='#FE5164'
    color={'white'}
    borderRadius={'5rem'}
    width={120}
    _hover={{background:'#f37d8b'}}
    onClick={handleSubmit}
    >
        
        <Link 
    href='/proyectos'
    >Buscar</Link></Button>
</Box>
</>
  ) : (
    <>
    <Input 
      placeholder='Buscar por nombre o comuna'
      size='md' 
      onChange={(e) => setSearch(e.target.value)}
      value={search}
      />
      {/* ICON  */}
      <Box
    m={'0.5rem'}
>
<Box
    background='#FE5164'
    color={'white'}
    borderRadius={'50%'}
    width={'50px'}
    height={'50px'}
    display={'flex'}
    justifyContent={'center'}
    alignItems={'center'}
    cursor={'pointer'}
    _hover={{background:'#f37d8b'}}
    onClick={() => onSubmmitHandler(search)}
    >
      <BiSearchAlt  size={30}/>
      
        </Box>
</Box>



</>
  )
}
</Flex>
    </Box>
    </Show>
    </>
  )
}

export default Search