import React from 'react';
import AgentProfile from './AgentProfile';
import { Description } from './Description';
import { Downloads } from './Downloads';
import { Features } from './Features';
import { Location } from './Location';
import { PropertyDetails } from './PropertyDetails';

export const AgentSingleGridView = () => {
  return (
    <section id="contact" className="our-agent-single pt0 pb70">
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-9">
          <div className="row">
            <div className="col-lg-12">

            <Description />

            </div>
            <div className="col-lg-12">
            <Downloads />
            </div>
            <div className="col-lg-12">
             <Location />
            </div>
            <div className="col-lg-12">
              <div className="additional_details pb40 mt50 bb1">
                <PropertyDetails />
              </div>
            </div>
            <div className="col-lg-12">
             <Features />
            </div>
                      
            <div style={{ display: 'none'}} className="col-lg-12">
              <div className="property_sp_tour_info pb40 mt50 bb1">
                <h4 className="mb30">Schedule A Tour</h4>
                <form className="mb40" action="#">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input className="form-control form_control" type="text" placeholder="Date" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="my_profile_setting_input ui_kit_select_search form-group">
                        <select className="selectpicker" data-live-search="true" data-width="100%">
                          <option data-tokens="9:00AM">10:00 AM</option>
                          <option data-tokens="9:00AM">10:30 AM</option>
                          <option data-tokens="9:00AM">11:00 AM</option>
                          <option data-tokens="9:00AM">11:30 AM</option>
                          <option data-tokens="9:00AM">12:00 PM</option>
                          <option data-tokens="9:00AM">12:30 PM</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </form>
                <h4 className="mb30">Your Information</h4>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form_control" type="text" placeholder="Name" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form_control" type="text" placeholder="Phone" />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input className="form-control form_control" type="email" placeholder="Email" />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea className="form-control mb30" rows="4" placeholder="Enter Your Message"></textarea>
                        <button type="submit" className="btn btn-thm submit_btn mt10">SUBMIT A TOUR REQUEST</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div style={{ display: 'none'}} className="col-lg-12">
              <div className="property_sp_walkscore pb50 mt50 bb1">
                <h4 className="mb10">Walkscore</h4>
                <ul className="nav nav-tabs" id="myTab3" role="tablist">
                  <li className="nav-item" role="presentation">
                    <a className="nav-link active" id="score-tab" data-toggle="tab" href="#score" role="tab" aria-controls="score" aria-selected="true">SCORES</a>
                  </li>
                  <li className="nav-item" role="presentation">
                    <a className="nav-link" id="nearby-tab" data-toggle="tab" href="#nearby" role="tab" aria-controls="nearby" aria-selected="false">NEARBY</a>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent3">
                  <div className="tab-pane fade show active" id="score" role="tabpanel" aria-labelledby="score-tab">
                    <div className="walkscore_area mt25">
                      <h4 className="title mb20">208 Olean Avenue</h4>
                      <div className="iba_container">
                        <div className="icon_box_area">
                          <div className="score"><span>80</span></div>
                          <div className="details">
                            <h5>Vert Walkable</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                        <div className="icon_box_area">
                          <div className="score"><span>70</span></div>
                          <div className="details">
                            <h5>Good Transit</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                        <div className="icon_box_area">
                          <div className="score"><span>95</span></div>
                          <div className="details">
                            <h5>Vert Bikeable</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                      </div>
                      <a className="text-thm" href="#">View map of nearby restaurants, grocery stores, and more.</a>
                      <input className="form-control mt15" type="text" placeholder="Get scores for your address" />
                    </div>
                  </div>
                  <div className="tab-pane fade" id="nearby" role="tabpanel" aria-labelledby="nearby-tab">
                    <div className="walkscore_area mt25">
                      <h4 className="title mb20">208 Olean Avenue</h4>
                      <div className="iba_container">
                        <div className="icon_box_area">
                          <div className="score"><span>80</span></div>
                          <div className="details">
                            <h5>Vert Walkable</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                        <div className="icon_box_area">
                          <div className="score"><span>70</span></div>
                          <div className="details">
                            <h5>Good Transit</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                        <div className="icon_box_area">
                          <div className="score"><span>95</span></div>
                          <div className="details">
                            <h5>Vert Bikeable</h5>
                            <p>Most errands can be accomplished on foot</p>
                          </div>
                        </div>
                      </div>
                      <a className="text-thm" href="#">View map of nearby restaurants, grocery stores, and more.</a>
                      <input className="form-control mt15" type="text" placeholder="Get scores for your address" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'none'}}className="col-lg-12">
              <div className="whats_nearby pb50 mt50 bb1">
                <h4 className="mb20">Whats Nearby</h4>
                <div className="education_distance mb30">
                  <h5><span className="icon"><span className="flaticon-graduate-cap"></span></span>Education</h5>
                  <div className="single_line">
                    <p className="para">Eladias Kids <span>(3.13 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Gear Up With ACLS <span>(4.66 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Brooklyn Brainery <span>(3.31 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                </div>
                <div className="education_distance mb30">
                  <h5><span className="icon"><span className="flaticon-heartbeat"></span></span> Health & Medical</h5>
                  <div className="single_line">
                    <p className="para">Eladias Kids <span>(3.13 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Gear Up With ACLS <span>(4.66 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Brooklyn Brainery <span>(3.31 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                </div>
                <div className="education_distance">
                  <h5><span className="icon"><span className="flaticon-house-1"></span></span> Real Estate</h5>
                  <div className="single_line">
                    <p className="para">Eladis Kids <span>(3.13 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Gear Up With ACLS <span>(4.66 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                  <div className="single_line">
                    <p className="para">Brooklyn Brainery <span>(3.31 miles)</span></p>
                    <ul className="review">
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><a href="#"><i className="fa fa-star"></i></a></li>
                      <li className="list-inline-item"><span className="total_rive_count">8895 reviews</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: 'none'}} className="col-lg-12">
              <div className="chart_circle_doughnut pb50 mt50 bb1">
                <h4 className="mb20">Mortgage Calculator</h4>
                <canvas className="mt50 mb50" id="myChart"></canvas>
                <div className="property_sp_payment_calculator">
                  <div className="row">
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Total Amount" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Down Payment" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Interest Rate" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Loan Terms (Years)" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Property Tax" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-4">
                      <div className="form-group">
                        <input type="text" className="form-control form_control" placeholder="Home Insurance" />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <button type="submit" className="btn btn-thm submit_btn mt10">CALCULATE</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

           
 
           
          
          </div>
        </div>
       <AgentProfile />
      </div>
    </div>
  </section>  
  );
};
