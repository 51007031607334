import React from 'react'
import { Breadcums } from '../components/Breadcums'
import { Contact } from '../components/Contact'

export const ContactPage = () => {
    return (
        <>
        <Breadcums currentPage="Contacto"  />
        <Contact />
        </>
    )
}
