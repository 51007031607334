import React from 'react'

export const Brands = () => {
    return (
        <section id="our-partners" className="our-partners bt1 pt60 pb60">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_1_69d53a4bda.jpg?updated_at=2022-03-19T13:33:53.664Z" alt="1.png" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_dfe4d364e3.jpg?updated_at=2022-03-19T13:33:53.549Z" alt="2.png" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_6_090882b9ca.jpg?updated_at=2022-03-19T13:33:53.546Z" alt="3.png" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_3_da960656ef.jpg?updated_at=2022-03-19T13:33:53.546Z" alt="4.png" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_4_fc0d70fa0c.jpg?updated_at=2022-03-19T13:33:53.545Z" alt="5.png" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-2">
                        <div className="our_partner">
                            <img src="https://app.goodprop.cl/uploads/Diseno_sin_titulo_5_47f3c78014.jpg?updated_at=2022-03-19T13:33:53.524Z" alt="5.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
