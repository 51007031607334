import React from 'react'

export const PropertyList = ({suggestions, listProps}) => {


  //when suggestions change we need to re-render the list
  //this is a bit of a hack, but it works
 



  return (
    <div className="row">

{
        suggestions?.length === 0? (
          listProps.map((property) => {
            return (
              <div key={property} className="col-lg-6 col-xl-4">
            <div className="feat_property">
              <div className="thumb">
                <a href={`/proyecto/${property.slug}`}>
                <img className="img-whp" src={property.img} alt="fl1.jpg" />
                </a>
                <div className="thmb_cntnt">

{
  property.tags?.toString().split(', ')[0]  &&                   <ul className="tag mb0">
  <li className="list-inline-item">{property.tags && <a href={  `/proyecto/${property.slug}`}>{property.tags?.toString().split(', ')[0]}</a>}</li>
</ul>
}

                
                {
                  property.tags?.toString().split(', ')[1]         &&       <ul className="tag2 mb0">
                  <li className="list-inline-item">{property.tags && <a href={  `/proyecto/${property.slug}`}>{property.tags?.toString().split(', ')[1]}</a> }</li>
                </ul>
                }


                </div>
                <div className="thmb_cntnt2">
                  <ul className="listing_gallery mb0">
                    <li className="list-inline-item"><a className="text-white" href={  `/proyecto/${property.slug}`}><span className="flaticon-photo-camera mr5"></span> 22</a></li>
                    <li className="list-inline-item"><a className="text-white" href={  `/proyecto/${property.slug}`}><span className="flaticon-play-button mr5"></span> 3</a></li>
                  </ul>
                </div>
              </div>
              <div className="details">
                <div className="tc_content">
                  {/* <div className="badge_icon"><a href={  `/proyecto/${property.slug}`}><img src="images/agent/3.png" alt="3.png" /></a></div> */}
                  <h4><a href="page-listing-single-v8.html">{property.name}</a></h4>
                  <p>{property.city}</p>
                  <ul className="prop_details mb0">
                    <li className="list-inline-item"><a href={  `/proyecto/${property.slug}`}><span className="flaticon-bed"></span> <br/>{property.bedrooms} </a></li>
                    <li className="list-inline-item"><a href={  `/proyecto/${property.slug}`}><span className="flaticon-bath"></span> <br/>{property.bathrooms} </a></li>
                    <li className="list-inline-item"><a href={  `/proyecto/${property.slug}`}><span className="flaticon-car"></span> <br/>{property.garage} </a></li>
                    <li className="list-inline-item"><a href={  `/proyecto/${property.slug}`}><span className="flaticon-ruler"></span> <br/>{property.area}mt²</a></li>
                  </ul>
                </div>
                <div className="fp_footer">
                  <ul className="fp_meta float-left mb0">
                    <li className="list-inline-item">
                      <a href={  `/proyecto/${property.slug}`}>
                       
                        <span className="heading-color fw600">Desde UF {property.price} <br/>  <span>Hasta &nbsp;UF {property.price2}</span> </span>
                      </a>
                    </li>
                  </ul>
                  <ul className="fp_meta float-right mb0">
                    <li className="list-inline-item"><a className="icon" href={  `/proyecto/${property.slug}`}><span className="flaticon-resize"></span></a></li>
                    <li className="list-inline-item"><a className="icon" href={  `/proyecto/${property.slug}`}><span className="flaticon-add"></span></a></li>
                    <li className="list-inline-item"><a className="icon" href={  `/proyecto/${property.slug}`}><span className="flaticon-heart-shape-outline"></span></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          )
          })
        ) : (
          suggestions.map((item) => {
            return (
                <div key={item} className="col-lg-6 col-xl-4">
              <div className="feat_property">
                <div className="thumb">
                  <a href={  `/proyecto/${item.slug}`}>
                  <img className="img-whp" src={item.img} alt="fl1.jpg" />
                  </a>
                  <div className="thmb_cntnt">
           
                      <ul className="tag mb0">
                      <li className="list-inline-item">
                      {item.tags[0] && <a href={  `/proyecto/${item.slug}`}>{item.tags?.toString().split(', ')[0]}</a>}
                      </li>
                    </ul>
                    {item.tags?.toString().split(', ')[1].length > 0 &&               
<ul className="tag2 mb0">

                      <li className="list-inline-item">
                       <a href={  `/proyecto/${item.slug}`}>{item.tags?.toString().split(', ')[1]}</a>

                      </li>
          
                    </ul>
                    }
                  </div>
                  <div className="thmb_cntnt2">
                    <ul className="listing_gallery mb0">
                      <li className="list-inline-item"><a className="text-white" href={  `/proyecto/${item.slug}`}><span className="flaticon-photo-camera mr5"></span> 22</a></li>
                      <li className="list-inline-item"><a className="text-white" href={  `/proyecto/${item.slug}`}><span className="flaticon-play-button mr5"></span> 3</a></li>
                    </ul>
                  </div>
                </div>
                <div className="details">
                  <div className="tc_content">
                    <h4><a href="page-listing-single-v8.html">{item.name}</a></h4>
                    <p>{item.city}</p>
                    <ul className="prop_details mb0">
                      <li className="list-inline-item"><a href={  `/proyecto/${item.slug}`}><span className="flaticon-bed"></span> <br/>{item.bedrooms} </a></li>
                      <li className="list-inline-item"><a href={  `/proyecto/${item.slug}`}><span className="flaticon-bath"></span> <br/>{item.bathrooms} </a></li>
                      <li className="list-inline-item"><a href={  `/proyecto/${item.slug}`}><span className="flaticon-car"></span> <br/>{item.garage} </a></li>
                      <li className="list-inline-item"><a href={  `/proyecto/${item.slug}`}><span className="flaticon-ruler"></span> <br/>{item.area}mt²</a></li>
                    </ul>
                  </div>
                  <div className="fp_footer">
                    <ul className="fp_meta float-left mb0">
                      <li className="list-inline-item">
                        <a href={  `/proyecto/${item.slug}`}>
                          <span className="heading-color fw600">
                            Desde UF {item.price} <br/> Hasta UF {item.price2} </span>
                        </a>
                      </li>
                    </ul>
                    <ul className="fp_meta float-right mb0">
                      <li className="list-inline-item"><a className="icon" href={  `/proyecto/${item.slug}`}><span className="flaticon-resize"></span></a></li>
                      <li className="list-inline-item"><a className="icon" href={  `/proyecto/${item.slug}`}><span className="flaticon-add"></span></a></li>
                      <li className="list-inline-item"><a className="icon" href={  `/proyecto/${item.slug}`}><span className="flaticon-heart-shape-outline"></span></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            )
        })
        )
        
        } 
    
            

           
            
            {/* <div className="col-lg-12">
              <div className="mbp_pagination mt10">
                <ul className="page_navigation">
                  <li className="page-item"><a className="page-link" href="#" tabIndex="-1"><span className="fa fa-arrow-left"></span></a></li>
                  <li className="page-item"><a className="page-link" href="#">1</a></li>
                  <li className="page-item"><a className="page-link" href="#">2</a></li>
                  <li className="page-item active" aria-current="page"><a className="page-link" href="#">3<span className="sr-only">(current)</span></a></li>
                  <li className="page-item"><a className="page-link" href="#">4</a></li>
                  <li className="page-item"><a className="page-link" href="#">5</a></li>
                  <li className="page-item"><a className="page-link" href="#">...</a></li>
                  <li className="page-item"><a className="page-link" href="#">15</a></li>
                  <li className="page-item"><a className="page-link" href="#"><span className="fa fa-arrow-right"></span></a></li>
                </ul>
              </div>
            </div> */}
          </div>
  )
}
