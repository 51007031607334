import React from 'react'
import { PropertiesSlider } from './PropertiesSlider'

export const Properties = () => {
    return (
        <section className="recent-property pb70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="main-title text-center">
                            <h2 style={{ fontSize: 20 }}>¿Ya viste nuestros proyectos?</h2>
                            <p>Tenemos una gran variedad para que puedas elegir donde invertir</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="recent_property_slider_home1">

                            <PropertiesSlider />


                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
