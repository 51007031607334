import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
const classNames = require('classnames');
import ReactMarkdown from 'react-markdown'



export const Description = () => {
    const { slug } = useParams(); 
    const [dsc, setDescription] = useState('')

    useEffect(() => {
        const url = `https://app.goodprop.cl/api/properties?filters[slug][$eq]=${slug}`;

        const fetchTags = async () => {
            try {
                const response = await fetch(url);
                const json = await response.json();
                console.log(json)
                setDescription(json?.data[0]?.attributes);
            } catch (error) {
                console.log("error", error);
            }
        }

        fetchTags();

    }, [])



    const [showMore, setShowMore] = useState(false);

  return (
    <div className="listing_single_description mb60">
    <h4 className="mb30">Descripción</h4>
    <p className={classNames("first-para mb25")} >
        {
            showMore ? <ReactMarkdown>{dsc?.descripcion}</ReactMarkdown> : <ReactMarkdown>{dsc?.descripcion?.substring(0, 250)}</ReactMarkdown>
        }
    </p>

    <p className="overlay_close">
        <a onClick={() => setShowMore(!showMore)} className="text-thm fz15 tdu" >
            { showMore ?   'Ver menos' : 'Ver más' }     
        </a></p>
  </div>
  );
};
