import React, { useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';
import mapMarker from '../../assets/mapmarker.png'
import Geocode from "react-geocode";
import PropTypes from 'prop-types';
import {exampleMapStyles} from './mapStyles';
import { useNavigate } from 'react-router-dom';




export const Map = ({suggestions}) => {

    const APIMAP = "AIzaSyDqqzjSzIcTnoHBM9qk09t1psZq-Bhv0mE"
    let navigate = useNavigate();
    console.log(navigate, "navigate");
    const [propertieLocation, setPropertieLocation] = useState([])
    const [activeMarker, setActiveMarker] = useState(null);

    const imgBaseURL = 'https://app.goodprop.cl'
    useEffect(() => {
        const getLocationProperties = async () => {
            const res = await fetch("https://app.goodprop.cl/api/properties?populate=*&pagination[start]=0&pagination[limit]=-1");
            const json = await res.json();
            setPropertieLocation(json?.data);
        }
    
        getLocationProperties();
    }, [])


    console.log(propertieLocation, "propertieLocation");


    const handleActiveMarker = (marker) => {
      if (marker === activeMarker) {
        return;
      }
      setActiveMarker(marker);
    };




Geocode.setApiKey(APIMAP);
Geocode.setLanguage("es");
Geocode.setRegion("cl");

    const mapStyles = {
        height: "50vh",
        width: "100%",
        borderRadius: "10px"
    };


      const defaultCenter = {
        
        lat: -33.45694,
        lng: -70.64827
        
    }
    
    const onLoad = infoWindow => {
      console.log('infoWindow: ', infoWindow)
    }





  return (

    <LoadScript
    googleMapsApiKey={APIMAP}>
    <GoogleMap
        options={{
            styles: exampleMapStyles,
        }}

        mapContainerStyle={mapStyles}
        zoom={10}
        center={defaultCenter}

    >
{
  
}

        {suggestions?.length !== 0   ? (suggestions.map((property) => {
    return (
      <>
        <Marker
            
            key={property.id}
            icon={mapMarker}
            position={{
                lat: Number(property.lat),
                lng: Number(property.lng),
            }}
            animation={window.google.maps.Animation.DROP}
            onClick={() => handleActiveMarker(property.id)}                
            >
      {activeMarker === property.id ? (
   <InfoWindow 

onLoad={onLoad}
onCloseClick={() => setActiveMarker(null)}
options={{ enableEventPropagation: true,
         closeBoxURL: ``, 
}}
>
<div style={{position: 'absolute'}}>
<div className="infoBox" style={{width: '320px', cursor: 'default'}}>
<div className="map-box">
    <div className="map-listing-item">
        <div className="inner-box">
            <div onClick={() => setActiveMarker(null)} className="infoBox-close"><i className="fa fa-times"></i></div>
            <div className="infoBox-redirect"><i className="fa fa-external-link"></i></div>

            <div className="image-box">
                <figure className="image"><img src={property.img} alt="" /></figure>
                <div className="content">
                    <h3>
                        <a href="listing-single.html">{property.name} <span className="icon icon-verified"></span></a>
                    </h3>
                    <ul className="info">
                        <li><span className="flaticon-phone"></span>{property.telefono} </li>
                    </ul>
                </div>
            </div>

            <div className="bottom-box">
            </div>
        </div>
    </div>
</div>
</div>
</div>
</InfoWindow>
  ) : null}
        </Marker>




</>
    )
})) : (propertieLocation.map((property) => {
            return (
              <>
                <Marker
                    
                    key={property.id}
                    icon={mapMarker}
                    position={{
                        lat: Number(property.attributes.latitude),
                        lng: Number(property.attributes.longitude),
                    }}
                    animation={window.google.maps.Animation.DROP}
                    onClick={() => handleActiveMarker(property.id)}                
                    >
              {activeMarker === property.id ? (
           <InfoWindow 
      
      onLoad={onLoad}
      onCloseClick={() => setActiveMarker(null)}
      options={{ enableEventPropagation: true,
                 closeBoxURL: ``, 
      }}
    >
    <div style={{position: 'absolute'}}>
    <div className="infoBox" style={{width: '320px', cursor: 'default'}}>
        <div className="map-box">
            <div className="map-listing-item">
                <div className="inner-box">
                    <div onClick={() => setActiveMarker(null)} className="infoBox-close"><i className="fa fa-times"></i></div>
                    <div className="infoBox-redirect"><i className="fa fa-external-link"></i></div>

                    <div className="image-box">
                        <figure className="image"><img src={imgBaseURL+property.attributes.featured_image.data.attributes.url} alt="" /></figure>
                        <div className="content">
                            <h3>
                                <a href="listing-single.html">{property.attributes.nombre} <span className="icon icon-verified"></span></a>
                            </h3>
                            <ul className="info">
                                <li><span className="flaticon-phone"></span>{property.attributes.broker.data.attributes.telefono} </li>
                            </ul>
                        </div>
                    </div>

                    <div className="bottom-box">
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </InfoWindow>
          ) : null}
                </Marker>


    

</>
            )
        }))}

    </GoogleMap>
</LoadScript>
  );
};

Map.propTypes = {
    ubicacion: PropTypes.string,
    comuna: PropTypes.string,
};